@import "../../theme.styles.scss";
.stationInfo {
  display: flex;
  flex-direction: row;
  li {
    svg {
      opacity: 0.7 !important;
    }
  }
  .miniMenu {
    ul {
      grid-template-columns: repeat(2, 40px);
    }
  }
  .emoji-mart-title-label {
    display: none;
  }
  .emoji-mart-preview {
    height: 30px;
    svg {
      height: 30px;
    }
  }
  .emoji-mart-preview-emoji {
    display: none;
  }
}
.si__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: auto;

  input {
    // width: auto;
    background: transparent;
    border: 1px dotted rgba(0, 0, 0, 0);
    padding: 0px 0px 0px 1px;
    border-radius: 5px;
    outline: none;
    &::placeholder {
      color: #333;
    }

    &:hover {
      border-bottom: 1px dotted gray;
    }
    &:focus {
      border-bottom: 1px dotted gray;
      &::placeholder {
        color: gray;
      }
    }
  }
}
.si__name {
  display: flex;
  flex-direction: row;
  align-items: center;
  // width: 100vw;
  // form {
  //   max-width: 40vw;
  // }
  div {
    white-space: nowrap;
  }
  input {
    width: auto;
    font-size: 1em;
    font-weight: 800;
  }
  form {
    input {
      color: $header1;
    }
    ::placeholder {
      color: $header1;
    }
  }
  button {
    // background: $background1;
    color: $header1;
    border: none
  }
}
.stationInfo .EmojiIcon {
  position: relative;
  // right: -4px;
  // width: 40px;
  top: 1px;
  // left: -6px;
}
.breadcrumb__spaceName {
  padding: 0px 0px 0px 1px;
  font-size: 1em;
  font-weight: 800;
  color: $header1;
  font-family: "Noto Sans JP", sans-serif;
  display: flex;
  align-items: center;
  column-gap: 5px;
  .retroButton {
    width: 18px;
    height: 18px;
    padding: 0px;
    div {
      font-weight: 500;
    }
  }
  h2 {
    display: flex;
    font-weight: 800;
    font-size: 1em;
  }
}
.si__breadcrumb_arrowleft {
  margin-left: 10px;
  margin-top: 0px;
}
.si__breadcrumb_arrowright {
  margin-top: 3px;
  margin-left: 8px;
  margin-right: 8px;
}
.si__buttonsgroup {
  display: flex;
  column-gap: 15px;
}
.sm__favorite {
  position: relative;
  top: 6px;
  // top: 20px;
  // left: 370px;
  color: $header2;
  z-index: 81;
  svg {
    cursor: pointer;
    opacity: 0.6;
    width: 15px;
    height: 15px;
    transition: 0.2s ease;
  }
}
.sm__settings {
  position: relative;
  top: 4px;
  // top: 18px;
  // left: 400px;
  z-index: 81;
  svg {
    cursor: pointer;
    opacity: 0;
    width: 20px;
    height: 20px;
    transition: 0.2s ease;
    color: $primary;
  }
}
.si__info_svg {
  margin-top: 7px;
}
.si__description-group {
  display: flex;
  column-gap: 5px;
  align-items: flex-start;
  min-width: 200px;
}
.si__description-overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}
.si__description-content {
  position: absolute;
  left: -20em;
  top: 30px;
}
.si__description {
  position: relative;
  padding-top: 8px;
  // width: 24em;
  // top: 42px;
  // left: 90px;
  opacity: 1;

  z-index: 80;
  textarea {
    padding: 5px;
    font-size: 0.8em;
    // width: 10em;
    border-radius: 5px;
    width: 29em;
    background: white;
    min-height: 6em;
    box-shadow: 4px 4px 12px 2px #00000021;
    &::placeholder {
      color: #333;
    }
  }
}
.si__btns {
  margin-top: 20%;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1100px) {
  .sm__settings {
    opacity: 1;
    svg {
      opacity: 0.2;
    }
  }
}

@media (max-width: 500px) {
  .si__description {
    display: none;
  }
  // .sm__favorite {
  //   left: 340px;
  // }
  .sm__settings {
    opacity: 1;
    // left: 360px;
    svg {
      opacity: 0.2;
    }
  }
  .breadcrumb__spaceName {
    font-size: 14px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    h2 {
      display: none;
    }
    .retroButton {
      width: 22px;
      height: 22px;
    }
  }
  // .breadcrumb__spaceName {
  //   // height: 100%;
  //   // display: flex;
  //   // align-items: center;
  //   width: 16px;
  //   display:block;
  //   visibility:hidden;
  // }

  // .breadcrumb__spaceName:first-letter {
  //   visibility:visible;
  // }

  .si__name form {
    max-width: 110px;
  }
  .si__name input {
    font-size: 14px;
    max-width: 110px;
  }

  .si__breadcrumb_arrowleft {
    display: none;
  }
  .si__breadcrumb_arrowright {
    margin-top: 2px;
    font-size: 0.9rem;
  }
  .si__description-group {
    display: none;
  }

  // .si__buttonsgroup {
  //   position: absolute;
  //   right: 110px;
  // }
  .si__buttonsgroup {
    column-gap: 10px;
  }
  .stationInfo .EmojiIcon {
    position: relative;
    // margin-right: 5px;
    // width: 40px;
    // top: 3px;
    // width: 24px;
    // left: -6px;
  }
}
