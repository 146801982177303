@import "../../../theme.styles.scss";

.notecard {
  overflow: hidden;
  // margin: 0px 6px;
  background: $paper;
  border-radius: 4px;
  break-inside: avoid;
  margin-bottom: 20px;
  padding: 12px 10px 8px 10px;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid $border1;
  color: $primary;
  // max-height: 400px;
  cursor: pointer;
  &:hover {
    box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.4);
    .notecard_footer-buttons {
      opacity: 1;
      transition: all 0.3s ease;
    }
    .notecard_pin-btn {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }
  // min-height: 120px;
  position: relative;
  transition: all 0.3s ease;
  iframe {
    display: flex;
    max-width: 100%;
  }
  img {
    max-width: 100%;
  }
  table {
    border-collapse: collapse;
    border-color: $border1;
    // border-radius: 2px;
    // border-style: hidden; /* hide standard table (collapsed) border */
    // box-shadow: 0 0 0 0.5px $primary; /* this draws the table border  */
    margin: 25px 0;
    td {
      padding: 5px 8px;
    }
  }
  h3 {
    font-weight: 500;
  }
}

.notecard_reader-container {
  margin-top: 12px;
  // max-height: 16em;
  overflow: hidden;
  margin-bottom: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 400px;
  .editor-input {
    padding: 8px 5px;
  }
  // display: flex;
  // flex-direction: column;
  b {
    font-weight: bold;
  }
  hr {
    // margin: 10px 0;
    border: 0;
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
  }
  span {
    display: inline-block;
    line-height: 0.6em;
    padding-bottom: 0.2em;
    // background-image: linear-gradient(to right,transparent 50%, currentBackgroundColor 100%);
  }
  pre {
    background-color: $primary;
    border: 1px solid $secondary2;
    color: $body;
    line-height: 1.8em;
    border-radius: 4px;
    display: block;
    padding: 5px 12px;
    margin: 5px 0;
  }
  img {
    max-width: 100%;
    // width: 150px;
    // aspect-ratio: auto;
    // overflow: auto;
    // resize: both;
  }
  iframe {
    display: flex;
    width: 100%;
  }
  a {
    // font-weight: 600;
    color: $brand;
    cursor: pointer;
  }
}

.notecard_reader-container::-webkit-scrollbar {
  display: none;
}

.notecard_reader-container::-webkit-scrollbar-track {
  width: 0px;
}

.notecard_footer {
  display: flex;
  position: relative;
  justify-content: space-between;
  column-gap: 10px;
  align-items: center;
  width: 100%;
  height: 30px;
  max-width: inherit;
  background: $paper;
  padding: 5px 0px;
  // margin-top: -20px;
  z-index: 10;
}

.notes__form-actions-sub-labels {
  max-width: calc(100% - 90px);
}

.notecard_footer-buttons {
  display: flex;
  column-gap: 10px;
  opacity: 0;
  // align-self: flex-end;
  align-items: center;
  position: absolute;
  right: 0;
  button {
    display: flex;
    align-items: center;
  }
  svg {
    color: $header1;
  }
}

// .notecard_label {
//   font-size: 0.8em;
//   font-weight: 550;
//   display: flex;
//   column-gap: 5px;
//   align-items: center;
//   padding: 3px 8px;
//   background: $secondary3;
//   border-radius: 5px;
//   width: fit-content;
//   button {
//     display: flex;
//     align-items: center;
//   }
// }

.notecard_pin-btn {
  position: absolute;
  opacity: 0;
  top: 2px;
  right: 2px;
  padding: 6px;
  transition: all 0.3s ease;
  cursor: pointer;
  & span {
    color: $header1;
  }
}

.notecard_archive-btn {
  position: absolute;
  bottom: 2px;
  right: 36px;
  padding: 6px;
  cursor: pointer;
  margin-top: 12px;
  & span {
    color: $header1;
  }
}

.notecard_delete-btn {
  position: absolute;
  bottom: 3px;
  right: 6px;
  padding: 6px;
  cursor: pointer;
  margin-top: 12px;
  & span {
    color: $header1;
  }
}

///STARTING CUSTOMIZING FROM HERE

.notecard details {
  width: 100%;
  margin: 0 auto;
  // background: #282828;
  margin-bottom: 0.5rem;
  box-shadow: 0 0.1rem 0.8rem -0.5rem rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  overflow: hidden;
  .habitline {
    padding-left: 2rem;
  }
}

.notecard summary {
  padding: 0.5rem 1rem;
  display: flex;
  width: 100%;
  // background: #333;
  padding-left: 2.2rem;
  position: relative;
  cursor: pointer;
}

.notecard summary:before {
  content: "\203A";
  align-items: center;
  // color: black;
  // border-width: 0.4rem;
  // border-style: solid;
  // border-color: transparent transparent transparent black;
  position: absolute;
  // top: 1.3rem;
  left: 1rem;
  transform: rotate(0);
  transform-origin: 0.2rem 50%;
  transition: 0.5s transform ease;
}

.notecard details[open] summary {
  min-height: 2em;
}

/* THE MAGIC 🧙‍♀️ */
.notecard details[open] > summary:before {
  transform: rotate(90deg);
}

.notecard details summary::-webkit-details-marker {
  display: none;
}
