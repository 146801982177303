@import "./theme.styles.scss";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow: hidden;
  font-family: "Noto Sans JP", sans-serif;
  background: $body;
  color: $primary;
  // transition: 0.3s;
  transition: all 0.3s ease-in;
}
hr {
  margin: 20px 0;
  border: none;
  border-bottom: 2px solid $secondary2;
}
p {
  font-size: 0.8rem;
  color: $header1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  transform: none;
  padding: 0;
  margin: 0;
  text-transform: none;
  text-decoration: none;
  color: $header1;
  font-weight: 500;
  h4 {
    font-weight: 500;
  }
}
input {
  font-family: "Noto Sans JP", sans-serif;
}
// ul li {
//   list-style: none;
//   text-transform: none;
//   text-decoration: none;
// }

textarea {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 0.8rem;
  color: #333;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  color: $primary;
  cursor: pointer;
  background: none;
}

@media (max-width: 500px) {
  body {
    margin-bottom: 50px;
    overflow-y: auto;
  }
  input {
    font-size: 1rem;
  }
  p {
    font-size: 1rem;
  }
  textarea {
    font-size: 1rem;
  }
}
