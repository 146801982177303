@import "../../theme.styles.scss";
// .stationItem {
//   // border:2px solid black;
//   // border-radius: 6px 0px 0px 6px;
//   margin-bottom: 5px;
//   .EmojiIcon {
//     width: 2em;
//     margin-right: 0.2em;
//     margin-left: 0;
//   }
//   &:last-child {
//     margin-bottom: 0;
//   }
//   .retroButton {
//     justify-content: flex-start;
//     box-shadow: none;
//     width: 100%;
//     border-radius: 6px 0px 0px 6px;
//     // background: $secondary2;
//     padding-left: 10px;
//     // cursor: none;
//     svg {
//       margin-right: 10px;
//       font-weight: 200;
//       opacity: 1;
//     }
//     &:hover {
//       background: #f0f0f0;
//     }
//   }
// }

.foldersItem {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  // min-width: 170px;
  min-height: 60px;
  // border-top: 1px solid $secondary2;
  // border-left: 1px solid $secondary2;
  // border-bottom: 1px solid $secondary2;
  border-radius: 6px 0px 0px 15px;
  background: $body2;
  margin-bottom: 5px;
  padding-bottom: 5px;
  .EmojiIcon {
    width: 2em;
    margin-right: 0.2em;
    margin-left: 0;
  }
  // &:last-child {
  //   margin-bottom: 8;
  // }
  .retroButton {
    justify-content: flex-start;
    box-shadow: none;
    width: 160px;
    // border: none;
    border-radius: 6px 0px 0px 6px;
    background: $body2;
    padding-left: 10px;
    // cursor: none;
    svg {
      margin-right: 8px;
      font-weight: 200;
      opacity: 1;
    }
    &:hover {
      background: #f0f0f0;
    }
  }
}

.foldersItem-close {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  // justify-content: start;
  height: 40px;
  // width: 180px;
  overflow: hidden;
  .fd__chevron-down {
    rotate: 90;
  }
  .EmojiIcon {
    width: 2em;
    margin-right: 0.2em;
    margin-left: 0;
  }
  // &:last-child {
  //   margin-bottom: 8;
  // }
  .retroButton {
    justify-content: flex-start;
    min-height: 36px;
    box-shadow: none;
    width: 160px;
    border-radius: 6px 0px 0px 6px;
    background: $body2;
    padding-left: 10px;
    // cursor: none;
    svg {
      margin-right: 8px;
      font-weight: 200;
      opacity: 1;
    }
    &:hover {
      background: $body1;
    }
  }
}

.foldersItemHeader {
  margin-bottom: 5px;
  .retroButton div {
    font-weight: 500;
    text-align: start;
    }
}
.foldersItemHeader-close {
  margin-bottom: 5px;
  .retroButton div {
    font-weight: 500;
    text-align: start;
    }
}

.infolder__items {
  // margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // row-gap: 10px;
  // .retroButton {
  //   // background: none;
  //   min-width: 150px;
  // }
  // &:last-child {
  //   margin-bottom: 8;
  // }
}
.infolder__items-active {
  // margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;
  .retroButton {
    // background: $body;
    min-width: 162px;
    border-right: 1px solid $body;
    border: 1px 1px 1px 0px solid $primary;
  }
  // &:last-child {
  //   margin-bottom: 0;
  // }
}

.project__drag {
  color: black;
  opacity: 0.1;
  transition: 0.2s ease;
  // cursor: pointer;
  // margin-left: 10px;
  &:hover {
    opacity: 0.4;
  }
}

@media (max-width: 500px) {
  .foldersItem {
    border-radius: 6px;
  }
  .foldersItem-close {
    .retroButton {
      // display: flex;
      border-radius: 10px;
      margin-bottom: none;
      width: 100%;
    }
  }

  .foldersItemHeader {
    // transform: rotate(-90deg);
    .retroButton {
      width: 100%;
      // font-size: 9px;
      border-radius: 8px 0px 0px 8px;
    }
  }

  // .infolder__items {
  //   position: absolute;
  // }
}
// @media (max-width: 500px) {
//   .foldersItem {
//     border-radius: 6px;
//     min-height: 32px;
//     min-width: 130px;
//     border: 1px solid $secondary2;
//     padding-bottom: 0px;
//     padding-right: 5px;
//     margin-bottom: 0px;
//     .retroButton {
//       // border-radius: 10px;
//       margin-bottom: none;
//       // width: 40px;
//     }
//   }
//   .foldersItem-close {
//     height: 100%;
//     .retroButton {
//       border-radius: 10px;
//       margin-bottom: none;
//       width: 130px;
//     }
//   }

//   .foldersItemHeader {
//     margin-bottom: 0px;
//     // width: 80px;
//     flex-direction: column;
//     // transform: rotate(-90deg);
//     .retroButton {
//       // width: 90px;
//       // font-size: 9px;
//       border-radius: 8px 0px 0px 8px;
//     }
//   }

//   .foldersItemOn {
//     display: flex;
//     // flex-direction: row;
//     width: 100%;
//     // height: 34px;
//     align-items: center;
//   }
//   .foldersItemIn {
//     display: flex;
//     // flex-direction: row;
//     width: 100%;
//     // height: 34px;
//     align-items: center;
//     padding-left: 10px;
//   }
//   // .infolder__items {
//   //   position: absolute;
//   // }
// }
