@import "../../../theme.styles.scss";

.anteditor {
  display: flex;
  flex-direction: column;
  height: 100%;
  &:hover {
    .anteditor_toolbar {
      opacity: 1;
      transform: translateY(0px);
      transition: all 0.3s ease-in-out;
    }
  }
  .x-todo li {list-style:none;}
  .x-todo-box {position: relative; left: -18px; margin-right: -16px;}
  // .x-todo-box input{position: absolute;}
  .x-todo {
    list-style: none;
    margin: 0;
    padding-left: 0;
  }
  
  .x-todo li.checklist-item {
    line-height: 2;      /* adjust as needed for consistent spacing */
    // margin: 0.2em 0;       /* adjust margin to match mobile */
  }
}

.anteditor_inner {
  min-height: 150px;
  height: 100%;
  padding: 0.5em;
  font-size: 1em;
  line-height: 1.5em;
  max-height: calc(100vh - 222px);
  overflow: auto;
  color: $primary;
  b {
    font-weight: bold;
  }
  hr {
    // margin: 10px 0;
    border: 0;
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
  }
  span {
    display: inline-block;
    line-height: 0.6em;
    padding-bottom: 0.2em;
    // background-image: linear-gradient(to right,transparent 50%, currentBackgroundColor 100%);
  }
  pre {
    background-color: $primary;
    border: 1px solid $secondary2;
    color: $body;
    line-height: 1.8em;
    border-radius: 4px;
    display: block;
    padding: 5px 12px;
    margin: 5px 0;
  }
  img {
    max-width: 100%;
    // width: 150px;
    // aspect-ratio: auto;
    // overflow: auto;
    // resize: both;
  }
  iframe {
    display: flex;
    width: 100%;
  }
  a {
    // font-weight: 600;
    color: $brand;
    cursor: pointer;
  }
  li {
    margin-left: 1em;
  }
  input[type="checkbox"] {
    margin-right: 5px;
  }
}

.anteditor_inner:focus {
  outline: none;
}

.anteditor table {
  border-collapse: collapse;
  border-color: $secondary2;
  // border-radius: 2px;
  // border-style: hidden; /* hide standard table (collapsed) border */
  // box-shadow: 0 0 0 0.5px $primary; /* this draws the table border  */
  margin: 25px 0;
  td {
    padding: 5px 8px;
  }
}

.anteditor_toolbar {
  display: flex;
  max-width: inherit;
  overflow-x: auto;
  column-gap: 4px;
  align-items: center;
  justify-content: space-between;
  color: $header1;
  padding: 8px 12px;

  // opacity: 0;
  // transform: translateY(-10px);
  // transition: all 0.5s ease-in-out;

  svg {
    width: 20px;
    height: 20px;
  }
  button {
    display: flex;
    align-items: center;
    color: $header1;
    border-radius: 4px;
    padding: 4px;
    align-items: center;
    &:hover {
      color: $primary;
      background: $secondary2;
    }
  }
}

.anteditor_toolbar::-webkit-scrollbar {
  display: none;
}

.anteditor_toolbar::-webkit-scrollbar-track {
  height: 0px;
}

.drop-item {
  display: flex;
  align-items: center;
  svg {
    &:last-child {
      width: 16px;
      height: 16px;
    }
  }
}

.anteditor_mini-toolbar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translateY(40px);
  column-gap: 4px;
  background: $paper;
  border: 1px solid $border1;
  border-radius: 6px;
  padding: 4px;
  z-index: 200;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: opacity 0.5s;
  // height: 35px;

  button {
    width: 28px;
    height: 28px;
    border-radius: 4px;
    padding: 4px;
    color: $primary;
    &:hover {
      color: $primary;
      background: $background2;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

[contenteditable="true"]:empty:not(:focus):before {
  content: attr(placeholder);
  color: gray;
  font-size: 0.95rem;
  display: block; /* For Firefox */
}

///STARTING CUSTOMIZING FROM HERE

.anteditor details {
  width: 100%;
  margin: 0 auto;
  // background: #282828;
  margin-bottom: 0.5rem;
  padding-left: 0.8rem;
  padding-bottom: 0.5rem;
  box-shadow: 0 0.1rem 0.8rem -0.5rem rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  overflow: hidden;
  .habitline {
    padding-left: 2rem;
  }
}

.anteditor summary {
  padding: 0.5rem 0.2rem;
  display: flex;
  width: 100%;
  // background: #333;
  font-weight: 500;
  padding-left: 1rem;
  position: relative;
  cursor: pointer;
}

.anteditor summary:before {
  content: "\203A";
  align-items: center;
  // color: black;
  // border-width: 0.4rem;
  // border-style: solid;
  // border-color: transparent transparent transparent black;
  position: absolute;
  font-size: large;
  top: 0.3rem;
  left: 0.2rem;
  transform: rotate(0);
  transform-origin: 0rem 50%;
  transition: 0.5s transform ease;
}

.anteditor details[open] summary {
  min-height: 2em;
}

/* THE MAGIC 🧙‍♀️ */
.anteditor details[open] > summary:before {
  transform: rotate(90deg);
}

.anteditor details summary::-webkit-details-marker {
  display: none;
}

.anteditorToolbarDropdown {
  row-gap: 10px;
  z-index: 1;
  background: antiquewhite;
  max-width: 140px;
  padding: 20px;
}

.dropdown {
  z-index: 100;
  display: block;
  position: absolute;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  margin-left: 62px;
  /* min-width: 100px; */
  padding: 5px;
  min-height: 40px;
  background-color: #fff;
}

.dropdown .item {
  /* margin: 0 8px 0 8px; */
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  /* justify-content: space-between; */
  background-color: #fff;
  border-radius: 8px;
  border: 0;
  column-gap: 10px;
  width: 100%;
  /* min-width: 158px; */
}

.dropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.dropdown .item:hover {
  background-color: #eee;
}

.dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  /* width: 200px; */
}

.dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  /* margin-right: 12px; */
  line-height: 16px;
  background-size: contain;
}

.dropdown-align {
  z-index: 100;
  display: block;
  position: absolute;
  top: 40px;
  left: 450px;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  /* min-width: 100px;
  min-height: 40px; */
  background-color: #fff;
}

.dropdown-insert {
  z-index: 100;
  display: block;
  position: absolute;
  padding: 5px;
  top: 40px;
  left: 475px;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  /* min-width: 100px;
  min-height: 40px; */
  background-color: #fff;
}

.editor_modal {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 10;
}
.editor_modal-overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  /* background: rgba(0, 0, 0, 0.384); */
}

.editor_imagelink {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  /* width: 200px; */
  /* height: 200px; */
  background: white;
  border-radius: 6px;
  padding: 30px 30px 15px 30px;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
  /* border: 1px solid #cccccc; */
  z-index: 11;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.editor_imagelink input {
  width: 240px;
  border: 1px solid #cccccc;
  padding: 5px 10px;
  border-radius: 4px;
  z-index: 11;
}

.editor_imagelink button {
  /* height: 20px; */
  border-radius: 6px;
  padding: 8px 20px;
  width: fit-content;
}
.editor_imagelink button:hover {
  background: #ebebeb;
}

.link-editor {
  position: absolute;
  z-index: 100;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  max-width: 300px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;
}

.link-editor .link-input {
  display: block;
  width: calc(100% - 24px);
  box-sizing: border-box;
  margin: 8px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  background-color: #eee;
  font-size: 15px;
  color: rgb(5, 5, 5);
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}
