@import "../../theme.styles.scss";

.mobileDrawer {
  position: fixed;
  left: 0px;
  top: 0px;
  // width: 100%;
  width: calc(100vw - 110px);
  background: $paper;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
  height: 100%;
  align-items: flex-start;
  z-index: 80;
  // box-shadow: 0px -2px 3px -2px rgba(0, 0, 0, 0.4);
  box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
  .retroButton {
    border: none;
  }
}
.mobileMenu__icon {
  border: 1px solid $secondary3;
  padding: 10px;
  border-radius: 10px;
  margin-right: 5px;
  background: $paper;
  svg {
    width: 30px !important;
    height: 30px !important;
  }
}

.drawer__headermockmobile {
  padding: 25px 25px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  // position: absolute;
  // top: -82px;
  left: 10px;
  height: 50px;
  padding: 0px 0px;
  z-index: 100;
  border-right: none;
  background: none;
  img {
    height: 36px;
  }
  a {
    display: flex;
    align-items: center;
  }
  button {
    transform: translateY(-5px);
  }
}

.drawer_sections {
  height: calc(100vh - 100px);
  width: 100%;
  // padding: 5px 0px;
  overflow-y: auto;
  overflow-x: hidden;
}

.drawer_section {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
  padding-left: 10px;
  h3 {
    margin-top: 10px;
    font-size: 0.9em;
    font-weight: 550;
  }
}

.drawer_grpselect {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 5px;
  button {
    font-size: 1em;
    font-weight: 550;
  }
}

.drawer_ws-icon {
  .retroButton {
    width: 30px;
    height: 30px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 4px;
  }
}

.drawer_wsIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 4px;
}

.drawer_settings {
  display: flex;
  width: 100%;
  height: 50px;
  padding-right: 10px;
  align-items: center;
  justify-content: space-between;
}

.drawer_wsoption {
  display: flex;
  column-gap: 5px;
}

.drawer_user {
  display: flex;
  align-items: center;
  column-gap: 10px;
  .avatar {
    width: 40px;
    height: 40px;
  }
}

.drawer__kmobile {
  display: flex;
  // justify-content: center;
  align-items: center;
}

@media (min-width: 500px) {
  // .mobileDrawer {
  //   display: none;
  // }
}
