@import "../../../../theme.styles.scss";
.addSubtask {
  margin-left: 9.5px;
}
.subtasks__input {
  input {
    background: none;
    color: $primary;
  }
  ::placeholder {
    color: $border2;
  }
}
