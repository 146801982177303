@import "../../theme.styles.scss";

.paywall_container {
  display: flex;
  //   .retroBox {
  //     position: relative;
  //     width: 70%;
  //     justify-content: center;
  //     left: 140px;
  //     bottom: 140px;
  //   }
  .retroBox {
    // position: relative;
    // max-width: 50%;
    // top: 100px;
    // left: 50%;
    // transform: translate(-50%, -50%);
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: center;
    // padding-top: 10px;
    // padding-bottom: 10px;
    // padding-right: 5px;
    // padding-left: 5px;
    // margin-left: 30px;
    // margin-right: 30px;
    min-width: 70vw;
    height: 100vh;
  }
  b {
    color: black;
    font-weight: 600;
  }
  i {
    color: black;
    font-weight: 600;
  }
  a {
    color: black;
    font-weight: 600;
  }
  p {
    font-size: 16px;
    line-height: 1.6;
    color: $primary;
    margin-bottom: 20px;
  }
  h2 {
    margin-bottom: 30px;
    text-align: center;
  }
  img {
    max-height: 270px;
    max-width: 320px;
    height: 100%;
  }
  .bl__content {
    @media (max-width: 580px) {
      width: 100%;
    }
  }
}
.invPremium__inner {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: center;
}
.cs__createPop {
  width: 550px;
}
.cs__create-btn {
  display: flex;
  justify-content: flex-end;
}

.paywall_columns {
  display: flex;
  flex-direction: row;
  padding-right: 30px;
  @media (max-width: 580px) {
    flex-direction: column;
    padding-right: 0px;
    margin-bottom: 10px;
  }
}

.paywall_columnleft {
  display: flex;
  flex-direction: column;
  // width: 200px;
  align-items: center;
  row-gap: 20px;
  padding-left: 20px;
  // margin-top: 30px;
  // justify-content: center;
  @media (max-width: 580px) {
    flex-direction: column;
    padding-left: 0px;
    justify-content: center;
    row-gap: 10px;
  }
  button {
    margin-top: 20px;
  }
  ul {
    margin-top: -10px;
    @media (max-width: 580px) {
      display: none;
    }
  }
  h3 {
    font-weight: 500;
    color: $secondary2;
    text-align: center;
    font-size: 1.4em;
    margin-right: 10px;
    // align-self: flex-start;
    // padding-left: 20px;
    @media (max-width: 580px) {
      display: none;
    }
  }
}

.paywall__plans {
  display: flex;
  flex-direction: column;
  // width: 200px;
  align-items: center;
  row-gap: 20px;
  // padding-left: 20px;
  // margin-top: 30px;
  // justify-content: center;
  @media (max-width: 580px) {
    flex-direction: row;
    padding-left: 0px;
    justify-content: space-center;
    column-gap: 10px;
    // width: 100%;
  }
  button {
    margin-top: 20px;
    @media (max-width: 580px) { 
      margin-top: 0px;
    }
  }
  ul {
    margin-top: -10px;
    @media (max-width: 580px) {
      display: none;
    }
  }
  h3 {
    font-weight: 500;
    color: $secondary2;
    text-align: center;
    font-size: 1.4em;
    margin-right: 10px;
    // align-self: flex-start;
    // padding-left: 20px;
    @media (max-width: 580px) {
      display: none;
    }
  }
}

.paywall_columnright {
  // display: flex;
  // width: "100%";
  // max-width: 1040px;
  margin-left: -2px;
  border: 2px solid $primary;
  border-radius: 10px;
  height: 728px;
  iframe {
    display: flex;
    width: calc(100vw - 270px);
    min-width: 360px;
    // width: 1040px;
    border-radius: 8px;
  }
  @media (max-width: 580px) {
    border-radius: 5px;
    margin-top: -2px;
    height: 100%;
  }
}

.planOption {
  font-weight: 500;
  font-size: large;
  border: 2px solid $bodyGray;
  width: 190px;
  border-radius: 8px 0px 0px 0px;
  padding: 10px 30px;
  border-right: 2px solid $body;
  @media (max-width: 580px) {
    font-size: medium;
    width: 170px;
    border-radius: 8px 8px 0px 0px;
    border-right: 2px solid $bodyGray;
  }
}

.selectedPlanOpt {
  font-weight: 600;
  font-size: large;
  border: 2px solid $primary;
  width: 200px;
  border-radius: 8px 0px 0px 0px;
  padding: 10px 30px;
  text-decoration: 3px solid underline $brand;
  background-color: $labelUp;
  transform: translateX(10px);
  @media (max-width: 580px) {
    font-size: medium;
    width: 175px;
    border-radius: 8px 8px 0px 0px;
    transform: translateX(0px);
    background-color: $body;
    border-bottom: 2px solid $body;
  }
}

.paywall__exit__button {
  // position: absolute;
  top: 15px;
  left: 90px;
  background: none;
  // margin-top: 4px;
  color: $brand;
  // align-self: end;
  // stroke-width: 5px;
  border: 2px solid $primary;
  border-radius: 8px;
  background-color: $body;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-start;
  // margin-bottom: 20px;
  // padding-right: 5px;
  // padding-left: 2px;
  // width: 50px;
  // height: 50px;
  // transform: rotate(-45deg);
  transition: 0.2s all ease;
  :hover {
    color: $brand;
    transition: 0.2s all ease;
    background-color: $bodyGray;
  }
  svg {
    // width: 36px;
    // height: 36px;
    border-radius: 6px;
    padding: 4px;
  }
}

.paywall_navheader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  column-gap: 5px;
  @media (max-width: 580px) {
    // display: none;
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
  }
}

@media (max-width: 500px) {
  .cs__createPop {
    width: 94% !important;
  }
}
