@import "../../../theme.styles.scss";

.notes__form-actions-sub-labels {
  display: flex;
  column-gap: 6px;
  align-items: center;
  // justify-content: flex-end;
  overflow-x: auto;
  overflow-y: hidden;
  color: $primary;
  input {
    max-width: 8em;
    border-radius: 6px;
    border: 1px solid $secondary2;
    padding: 2px 6px;
    font-size: 0.8em;
  }
}

.notes__form-actions-sub-labels::-webkit-scrollbar {
  display: none;
}
.notes__form-actions-sub-labels::-webkit-scrollbar-track {
  height: 0px;
}

.label_form_btn {
  display: flex;
  align-items: center;
  font-size: 0.8em;
  font-weight: 500;
  column-gap: 4px;
  background: $background2;
  padding: 3px 8px;
  border-radius: 5px;
  white-space: nowrap;
  button {
    // width: 0;
    opacity: 0;
    display: flex;
    align-items: center;
    transition: all 0.2s ease;
  }
  &:hover {
    button {
      width: 100%;
      opacity: 1;
      transition: all 0.2s ease;
    }
  }
}

// .label_form_btn-delete {
//   position: absolute;
//   display: inline-block;
//   align-self: flex-end;
//   background: white;
//   // right: 0;
// }

.label_form_btn-delete {
  svg {
    color: $primary
  }
}

.label_form_btn-plus {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  column-gap: 0px;
  background: $background2;
  // padding: 0px 6px;
  // padding-left: 6px;
  border-radius: 5px;
  input {
    display: flex;
    align-items: center;
    vertical-align: middle;
    border-radius: 4px;
    padding: 2px 6px;
  }
  button {
    display: flex;
    align-items: center;
    margin: 3px 3px;
  }
  svg {
    color: $primary;
  }
}

@media (max-width: 500px) {
  .label_form_btn-plus {
    display: flex;
  }
}
