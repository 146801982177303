@import "../../../theme.styles.scss";
.boxLayer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 250;
  color: $header1;
}
.bl__content {
  z-index: 200;

  // .retroBox {
  //   padding: 30px !important;
  // }
}
.bl__layer {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(0.2px);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
}

@media (max-width: 500px) {
  .bl__content {
    width: 92%;
  }
}
