@import "../../theme.styles.scss";
.views-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  column-gap: 10px;
  width: 100%;
  // margin-bottom: -1px;
  // width: calc(100vw - 320px);
}

.views {
  display: flex;
  align-items: flex-end;
  // justify-content: space-between;
  padding-left: 40px;
  column-gap: 10px;
  cursor: pointer;
  :nth-child(5) {
    margin-left: 40px;
  }
}

.views__view {
  padding: 6px 12px;
  color: black;
  padding-bottom: 5px;
  border-radius: 8px 8px 0px 0px;
  border-top: 1px solid $border1;
  border-left: 1px solid $border1;
  border-right: 1px solid $border1;
  border-bottom: 1px solid $border2;
  // margin-top: 2px;
  // margin-right: 10px;
  display: flex;
  align-items: center;
  &:hover {
    background: $paper;
    // background: #fafafa;
    // border-bottom: 1px solid #fafafa;
    transition: all 0.2s ease-in;
  }
  p {
    font-size: 13px;
    font-weight: 500;
    font-family: "Noto Sans JP", sans-serif;
  }
  svg {
    margin-right: 5px;
    opacity: 0.6;
    color: $primary;
  }
}
.views__view-active {
  padding: 6px 12px;
  color: $primary;
  // padding-bottom: 5px;
  border-radius: 8px 8px 0px 0px;
  border-top: 1px solid $border1;
  border-left: 1px solid $border1;
  border-right: 1px solid $border1;
  border-bottom: 1px solid $paper;
  // margin-top: 2px;
  // margin-right: 10px;
  display: flex;
  align-items: center;
  background: $paper;
  box-shadow: 2px -2px 4px -2px #00000021;
  &:hover {
    background: $paper;
    border-bottom: 1px solid $paper;
    transition: all 0.2s ease-in;
  }
  p {
    font-size: 13px;
    font-weight: 600;
    font-family: "Noto Sans JP", sans-serif;
    color: $primary;
  }
  svg {
    margin-right: 5px;
    opacity: 0.6;
  }
}

// .views__view-active::before {
//   content: "";
//   position: absolute;
//   top: 0px;
//   height: 50px;
//   left: -25px;
//   width: 25px;
//   border-top-right-radius: 25px;
//   box-shadow: 0 -25px 0 0 #000000;
// }

.chess_view {
  transition: margin 0.2s ease-in;
  border-radius: 0px 0px 8px 8px;
  border-bottom: 1px solid $border1;
  p {
    width: 0;
    opacity: 0;
    transition: all 0.5s ease;
    transition: opacity 0.3s ease;
  }
}
.chess_view-active {
  margin-right: 77px;
  transition: margin 0.2s ease-in;
  border-radius: 0px 0px 8px 8px;
  border-bottom: 1px solid $border1;
  p {
    width: auto;
    opacity: 1;
    transition: all 0.5s ease;
    transition: opacity 0.3s ease;
  }
}

.views__chess-and-modules {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  column-gap: 10px;
}

@media (max-width: 1180px) {
  .views {
    padding-left: 0;
    margin-top: 10px;
    justify-content: space-evenly;
    height: 36px;
    width: 100vw;
    max-width: fit-content;
    column-gap: 5px;
    :nth-child(5) {
      margin-left: 0px;
    }
  }
  .views-container {
    // width: 100vw;
    // justify-content: space-around;
  }
  .views__view {
    // padding: 7px 7px 7px 10px;
    p {
      display: none;
    }
  }
  .sm__buttomRow {
    flex-direction: column-reverse;
    height: fit-content;
    padding-left: 10px;
  }
  .sm__settings {
    opacity: 1;
    svg {
      opacity: 0.2;
    }
  }
  .no-mobile {
    display: none;
  }
  .views__chess-and-modules {
    position: absolute;
    right: 10px;
    bottom: 45px;
    .flat2 {
      border: none;
      background: none;
    }
    // display: none;
    // position: absolute;
    // bottom: 60px;
    // right: 20px;
    // z-index: 90;
  }
  .views__view-active {
    p {
      font-size: 15px;
    }
    svg {
      width: 18px;
      height: 18px;
    }
  }
  .views__view {
    p {
      font-size: 15px;
    }
    svg {
      width: 18px;
      height: 18px;
      margin-right: 0px;
    }
  }
}
