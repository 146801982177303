@import "../../theme.styles.scss";

// const Container = styled.div``;

.notes__form-container {
  display: flex;
}

.notes__form-flex {
  display: flex;
}

/* FORM CLOSED */

.notes__form-addbutton {
  position: fixed;
  height: 56px;
  width: 56px;
  right: 114px;
  bottom: 30px;
  padding: 6px 12px;
  color: $primary;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 50;
  background: $paper;
  box-shadow: 2px 2px 8px 1px #00000021;
  svg {
    opacity: 1;
    &:hover {
      opacity: 0.7;
    }
  }
  @media (max-width: 500px) {
    right: 90px;
    bottom: 40px;
  }
}

/* MODAL */

.notes__form-cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 100;
}

.notes__form-cover-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 100;
}

.notes__form-content {
  // width: 80%;
  width: 594px;
  height: calc(100% - 90px);
  background: $paper;
  position: relative;
  /* display: flex; */
  border-radius: 6px;
  box-shadow: 4px 4px 12px 2px #00000021;
  // right: 180px;
  top: 45px;
}

.notes__form-content-fullscreen {
  // width: 80%;
  display: flex;
  // min-width: 700px;
  // max-width: calc(100% - 590px);
  // align-self: flex-start;
  // justify-self: flex-start;
  // justify-content: flex-start;
  align-items: flex-start;
  width: 700px;
  height: calc(100% - 20px);
  background: $paper;
  position: relative;
  /* display: flex; */
  border-radius: 6px;
  box-shadow: 4px 4px 12px 2px #00000021;
  // right: 135px;
  margin-top: 10px;
  margin-bottom: 10px;
  .toolbar {
    justify-content: space-around;
  }
  .notes__form-cover {
    background: rgba(0, 0, 0, 0.5);
  }
  // .notes__form-scroll {
  //   max-width: calc(100% - 320px);
  // }
  // .notes__form-actions {
  //   max-width: calc(100% - 320px);
  // }
  img {
    max-height: calc(100vh - 245px);
    width: 100%;
    object-fit: cover;
  }
}

.notes__form-scroll {
  height: 100%;
  width: 100%;
  .editor-input {
    overflow-y: auto;
  }
  .anteditor_inner  {
    padding: 0.5em 1em;
  }
}

/* IMAGE */

.notes__form-image-preview {
  width: 100%;
  position: relative;
  img {
    max-width: 100%;
  }
}

// const Image = styled.img`
//   max-width: 100%;
// `;

.notes__form-image-delete-btn {
  position: absolute;
  bottom: 12px;
  right: 8px;
  padding: 4px;
  background: $paper;
  border-radius: 12px;
  cursor: pointer;
}

.notes__form-privacy-btn {
width: 60px;
svg{
  color: $primary;
}
}

/* INPUT & TEXTAREA */

.notes__form-input-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  width: 100%;
  padding-right: 16px;
  box-shadow: 0px 3px 4px -2px #00000021;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: $primary;
    }
  }
}

.notes__form-input-title {
  // margin-top: 16px;
  padding: 5px 16px;
  background: $paper;
  width: 100%;
  outline: none;
  border: none;
  font-size: 24px;
  font-weight: 500;
  color: $primary;
  border-radius: 8px 0px 0px 0px;
}

.notes__form-fullscreen-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $header1;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    transform: scale(1.1);
    transition: all 0.2s ease;
    color: $primary;
  }
}
.notes__form-exit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $header1;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    transform: scale(1.1);
    transition: all 0.2s ease;
    color: $primary;
  }
}

.notes__form-textarea {
  margin-top: 16px;
  margin-left: 16px;
  background: $paper;
  width: 90%;
  font-size: 16px;
  outline: none;
  border: none;
  height: 80px;
}

/* FORM ACTIONS */

.notes__form-actions {
  background: $paper;
  // border-top: 1px solid #bbb;
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 6px;
  border-radius: 0px 0px 6px 6px;
  box-shadow: 0px -3px 4px -2px #00000021;
  z-index: 5;
}

.notes__form-actions-sub {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 50px;
}

// .notes__form-actions-sub-labels {
//   display: flex;
//   column-gap: 6px;
//   align-items: center;
//   // justify-content: flex-end;
//   overflow-x: auto;
//   overflow-y: hidden;
//   input {
//     max-width: 8em;
//     border-radius: 6px;
//     border: 1px solid $secondary2;
//     padding: 2px 6px;
//     font-size: 0.8em;
//   }
// }

// .notes__form-actions-sub-labels::-webkit-scrollbar {
//   display: none;
// }
// .notes__form-actions-sub-labels::-webkit-scrollbar-track {
//   height: 0px;
// }

.notes__form-button {
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  margin-right: 12px;
  border-radius: 4px;
  font-weight: 400;
  color: $primary;
  & span {
    margin-left: 6px;
  }
  &:hover {
    background: $body;
  }
}

// .label_form_btn {
//   display: flex;
//   align-items: center;
//   font-size: 0.8em;
//   font-weight: 550;
//   column-gap: 4px;
//   background: $secondary3;
//   padding: 3px 8px;
//   border-radius: 5px;
//   button {
//     opacity: 0;
//     display: flex;
//     align-items: center;
//     transition: all 0.2s ease;
//   }
//   &:hover {
//     button {
//       opacity: 1;
//       transition: all 0.2s ease;
//     }
//   }
// }

// .label_form_btn-plus {
//   display: flex;
//   align-items: center;
//   font-size: 0.8em;
//   column-gap: 4px;
//   background: $secondary3;
//   padding: 3px 6px;
//   border-radius: 5px;
//   button {
//     display: flex;
//     align-items: center;
//   }
// }

@media (max-width: 500px) {
  .notes__form-content {
    // width: 80%;
    width: 100vw;
    height: 100%;
    background: $paper;
    position: relative;
    /* display: flex; */
    border-radius: 0px;
    box-shadow: 4px 4px 12px 2px #00000021;
    right: 0px;
    top: 0px;
    img {
      max-height: 35vh;
      width: 100%;
      object-fit: cover;
    }
    .toolbar {
      width: 100vw;
      overflow-y: scroll;
    }
    .toolbar::-webkit-scrollbar {
      display: none;
    }

    .toolbar::-webkit-scrollbar-track {
      height: 0px;
    }
  }

  .notes__form-content-fullscreen {
    // width: 80%;
    width: 100vw;
    height: 100%;
    background: $paper;
    position: relative;
    /* display: flex; */
    border-radius: 0px;
    box-shadow: 4px 4px 12px 2px #00000021;
    right: 0px;
    top: 0px;
    .notes__form-scroll {
      max-width: 100vw;
    }
    .notes__form-actions {
      max-width: 100vw;
    }
    img {
      max-height: 35vh;
      width: 100%;
      object-fit: cover;
    }
    .toolbar {
      width: 100vw;
      overflow-y: scroll;
    }
    .toolbar::-webkit-scrollbar {
      display: none;
    }

    .toolbar::-webkit-scrollbar-track {
      height: 0px;
    }
  }
  // .notes__form-actions {
  //   justify-content: flex-start;
  // }
  .notes__form-fullscreen-btn {
    display: none;
  }
  .notes__form-addbutton {
    position: fixed;
    // height: 52px;
    // width: 52px;
    padding: none;
    // left: calc(100vw - 150px);
    right: 20px;
    padding: 5px 15px;
    bottom: 128px;
    align-items: center;
    justify-content: center;
    // top: 300px;
    // left: 150px
  }
  .notes__form-cover {
    height: 100dvh;
  }
  .notes__form-button {
    font-size: 1em;
    font-weight: 550;
  }
}
