@import "../../theme.styles.scss";

.mobileMenu {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  background: white;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  // padding-top: 5px;
  height: 60px;
  padding-bottom: 15px;
  align-items: center;
  z-index: 80;
  box-shadow: 0px -2px 3px -2px rgba(0, 0, 0, 0.4);
  .retroButton {
    border: none;
  }
  button {
    svg {
      width: 25px !important;
      height: 25px !important;
    }
  }
}
.mobileMenu__icon {
  border: 1px solid $secondary3;
  padding: 10px;
  border-radius: 10px;
  margin-right: 5px;
  background: white;
  svg {
    width: 30px !important;
    height: 30px !important;
  }
}
.timer__hide-mobile {
  // position:   absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 3px;
  width: 25px;
  height: 25px;
  // flex-direction: column;
  font-size: 1.5em;
  .label {
    position: absolute;
  }
  svg {
    width: 0.5em;
    height: 0.5em;
  }
}
@media (min-width: 500px) {
  .mobileMenu {
    display: none;
  }
}
