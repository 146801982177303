@import "../../theme.styles.scss";

.favoriteStar {
}
.fs__star {
  cursor: pointer;
  transition: 0.2s ease;
  &:hover {
    transform: scale(1.1);
  }
}
.fs__true {
  svg {
    color: $primary !important;
    opacity: 0.8 !important;
  }
  &:hover {
    opacity: 1 !important;
  }
}
.fs__false {
  svg {
    color: $header2 !important;
  }
  &:hover {
    svg {
      color: $header1 !important;
    }
    opacity: 1 !important;
  }
}
.fs__item {
  .children {
  }
  svg {
    color: $primary;
    // width: 20px !important;
    // height: 20px !important;
    margin-bottom: 3px;
    margin-right: 5px;
  }
}
