@import "../../../theme.styles.scss";
.retroBox {
  box-shadow: $boxShadow-large;
  padding: 30px;
  border-radius: 5px;
  background: $paper;
}
@media (max-width: 500px) {
  .retroBox {
    width: 100%;
    padding: 20px;
  }
}
