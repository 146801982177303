.EmojiPick {
  // margin-top: 90px;
  // width: 30px;
  // max-width: 30px;
  z-index: 150;
  margin-right: 5px;
}

// .emoji-mart-light {
//   z-index: 1;
// }
// .emoji-mart-search input {
//     font-size: 10px;
// }

.PickerOn {
  position: absolute;
  // margin-top: 200px;
  // max-width: 100px;
  z-index: 300;
}
