@import "../../theme.styles.scss";

.journal {
  // text-align: center;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  padding: 10px;
  background: $paper;
  box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
  border-radius: 0px 5px 5px 5px;
  height: 100%;
  .editor-container {
    margin: 0px;
    display: flex;
    // flex-direction: column;
    height: 100%;
    // max-height: 60vh;
    // overflow-x: hidden;
    // min-height: 50vh;
  }
  .editor-inner {
    width: 100%;
  }
  .editor-input {
    padding: 5px 10px;
    overflow-y: auto;
    // display: flex;
    height: 100%;
    // min-height: 100px;
  }
  .actions {
    bottom: -30px;
    padding: 0px;
  }
  .action-button {
    margin-right: 0px;
  }
  .editor-placeholder {
    top: 5px;
    left: 11px;
  }
  .PlaygroundEditorTheme__listItem {
    margin: 0px;
    margin-bottom: 4px;
  }
  .PlaygroundEditorTheme__ul {
    margin-block-start: 5px;
    margin-left: 2px;
    line-height: 24px;
    margin-bottom: 6px;
  }
  .PlaygroundEditorTheme__ol1 {
    line-height: 24px;
    margin-bottom: 6px;
    margin-left: 10px;
  }
}

.journal__btn-group {
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  column-gap: 5px;
  right: 5px;
  input {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    padding-top: 2px;
  }
  input:focus {
    border: none;
    outline: none;
  }
  button {
    display: flex;
    text-align: center;
    width: 25px;
    height: 25px;
    background: $background2;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    &:last-child {
      width: auto;
      padding: 5px;
      font-weight: 550;
    }
  }
}

.journal__today-btn {
  width: auto;
  padding: 5px;
  padding-top: 2px;
  // text-decoration: underline 1.5px solid;
  button {
    font-weight: 550;
  }
}
.journal__today-btn-passive {
  color: $header2;
  padding-top: 2px;
  // text-decoration: line-through;
}

.journal__datepicker {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 60px;
  z-index: 100;
  .react-datepicker {
    z-index: 6;
  }
  .react-datepicker-wrapper .datepicker {
    width: 60px;
  }
  input {
    width: 25px;
    height: 25px;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    margin-top: -2px;
    padding-left: 5px;
    font-size: 13px;
  }
}

.journal__form-button {
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  margin-right: 12px;
  margin-top: -27px;
  border-radius: 4px;
  font-weight: 400;
  align-self: flex-start;
  & span {
    margin-left: 6px;
  }
  &:hover {
    background: #ddd;
  }
}

@media (max-width: 500px) {
  .journal {
    height: calc(100vh - 270px);
  }
  .journal__btn-group {
    column-gap: 8px;
    right: 5px;
    input {
      font-weight: 600;
      font-size: 16px;
    }
    button {
      width: 32px;
      height: 32px;
      font-weight: 550;
      font-size: 16px;
      svg {
        width: 16px;
        height: 16px;
      }
      &:last-child {
        width: auto;
        padding: 8px;
      }
    }
  }
  .journal__form-button {
    font-size: 16px;
  }
}
