@import "../../theme.styles.scss";
.listView {
  display: flex;
  padding-right: 20px;
  height: calc(100vh - 130px);
}
.listContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  // min-width: 75vw;
  background: $paper;
  border-radius: 8px 8px 8px 8px;
  height: calc(100vh - 100px);
  box-shadow: 2px 2px 8px 1px #00000021;
}
.list {
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  // height: 83.5vh;
  padding: 5px 15px;
  // background: white;
  // border-radius: 8px;
  // box-shadow: 2px 2px 8px 1px #00000021;
}
::-webkit-scrollbar-track {
  background: white;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3e3e3e;
}
.list__newStatus {
}
.list__dragDelete {
  // border-top: 2px solid rgba(0, 0, 0, 0.6);
  position: absolute;
  transition: 0.3s ease;
  padding: 20px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: $bodyGray;
  opacity: 0.5;
  // svg {
  //   color: rgba(0, 0, 0, 0.6);
  //   width: 25px !important;
  //   height: 25px !important;
  // }
  &:hover {
    height: 130px;
  }
}
// .list__placeholder {
//   padding: 10px;
// }

@media (max-width: 500px) {
  .listView {
    padding: unset;
    width: 100vw;
  }
  .list {
    height: calc(100vh - 130px);
  }
}
