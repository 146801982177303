@import "../../../../theme.styles.scss";
.statusType {
  margin: 8px;
  border-radius: 2px;
  height: fit-content;
  max-width: 280px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  flex: 1;
  padding-bottom: 150px;
  .retroBox {
    padding: 5px;
    box-shadow: none;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.02) 0%,
      rgba(238, 238, 238, 0) 100%
    );
    // max-height: calc(100vh - 150px);
    // overflow-y: scroll;
    // padding-bottom: 180px;
  }
  .retroBox::-webkit-scrollbar {
    display: none;
  }
  .retroBox::-webkit-scrollbar-track {
    height: 0px;
  }
  &:hover {
    .st__menu {
      opacity: 1;
    }
  }
}

.st__content {
  border-radius: 10px;
}
.st__taskList {
  padding: 8px;
  flex-grow: 1;
  min-height: 108px;
  transition: 0.2s background-color;
  // max-height: calc(100vh - 150px);
  // overflow-y: auto;
  // overflow-x: unset;
}
.st__taskList::-webkit-scrollbar {
  display: none;
}
.st__taskList::-webkit-scrollbar-track {
  height: 0px;
}
.st__newTask {
  margin-top: -5px;
  .retroInput {
    margin-bottom: 0px;
  }
  input {
    width: 80px;
    background: transparent;
    border: none;
    font-size: 12px;
    &:focus {
      width: 100%;
      background: white;
      transform: scale(1);
    }
  }
  form {
    padding: 0 15px;
  }
  ::placeholder {
    color: gray;
  }
}
.st__newTask-inside {
  margin-top: 2px;
  form {
    padding: 0;
  }
}
// .st__newTask-inputitem {
//   .retroInput {
//     margin-bottom: 0px;
//   }
// }

.st__header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3px;
}

.st__menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-right: 5px;
  margin-left: -20px;
  padding-right: 0px;
  padding-top: 0px;
  position: relative;
  width: 100%;
  opacity: 0;
  transition: 0.2s ease;
  .bll__content {
    left: -50%;
    top: 60px;
    .retroBox {
    }
  }
}

.st__settings,
.st__expand,
.st__drag {
  opacity: 0.1;
  transition: 0.2s ease;
  cursor: pointer;
  margin-left: 0px;
  &:hover {
    opacity: 0.4;
  }
}

.st__title {
  max-width: 88%;
  input {
    background: transparent;
    border: 1px dotted rgba(0, 0, 0, 0);
    padding: 3px 5px;
    border-radius: 5px;
    margin-right: 0px;
    outline: none;
    max-width: calc(100% - 20px);
    font-size: 18px;
    font-weight: 800;
    color: #333;
    &:hover {
      border: 1px dotted gray;
    }
    &:focus {
      border: 1px dotted gray;
      &::placeholder {
        color: gray;
      }
    }
  }
}

// vertical
.st__vertical {
  max-width: 100%;
  .st__taskList {
    min-height: 0px;
  }
}
.st__menu-vertical {
  .bll__content {
    left: -90px;
  }
}
.st__newTask-vertical {
  input {
    max-width: 300px;
  }
}

.st__arrow {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $header1;
  svg {
    transition: 0.2s ease;
    margin-top: 5px;
    &:hover {
      transform: scale(1.2);
    }
  }
}

@media (max-width: 500px) {
  .st__newTask {
    input {
      font-size: 14px;
      width: 100%;
    }
  }
}
