@import "../../theme.styles.scss";

.enterStation {
  width: 100%;
  // height: 100%;
  overflow: hidden;
  position: relative;
  background: $background2;
  // padding-top:90px;

  // .stationMenu {
  //   overflow: visible;
  // }
}
