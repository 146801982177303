@import "../../theme.styles.scss";
.dockHeader {
  // padding-left: 60px;
  // left: 0;
  position: relative;
  width: 100%;
  // height: 91px;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // background: $secondary2;
  border-bottom: 1px solid $secondary3;
  // box-shadow: 1px 1px 1px rgba(54, 54, 54, 0.1),
  //   2px 2px 2px rgba(54, 54, 54, 0.2), 3px 3px 3px rgba(54, 54, 54, 0.3);
  z-index: 80;
  &:hover {
    .dh__settings-icon {
      opacity: 1;
    }
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 1;
    opacity: 0.8;
  }
}
.dh__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: center;
  column-gap: 5px;
}

.dh__header .retroButton {
  width: 18px;
  height: 18px;
  padding-left: 0px;
  padding-right: 0px;
  color: black;
}

.dH__upperRow {
  display: flex;
  box-sizing: border-box;
  height: 40px;
  align-items: center;
  background: $background1;
  border-bottom: 1px solid $header2;
  // justify-content: flex-start;
  // padding-right: 100px;
  // box-shadow: 1px 1px 1px rgba(54, 54, 54, 0.1),
  //   2px 2px 2px rgba(54, 54, 54, 0.2), 3px 3px 3px rgba(54, 54, 54, 0.3);
  // z-index: 1;
  svg {
    color: $primary;
  }
}

.dH__bottomRow {
  display: flex;
  height: 40px;
  flex-direction: row;
  background: $background2;
  align-items: center;
  padding-left: 20px;
  h4 {
    font-weight: 500;
    color: $primary;
  }
}

// .dh__description,
.dh__name {
  input {
    font-size: 1.2em;
    font-weight: 800;
    background: transparent;
    border: 1px dotted rgba(0, 0, 0, 0);
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: -10px;
    outline: none;
    &::placeholder {
      color: $primary;
    }

    &:hover {
      border: 1px dotted gray;
    }
    &:focus {
      border: 1px dotted gray;
      &::placeholder {
        color: gray;
      }
    }
  }
}

.dh__settings {
  position: relative;
  // left: 20px;
  top: 4px;
  .bll__content {
    margin-top: 10px;
    .retroBox {
      min-height: auto;
    }
  }
}
.dh__btns {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  button {
    width: fit-content;
    height: 30px;
  }
}
.dh__settings-icon {
  cursor: pointer;
  transition: 0.2s ease;
  opacity: 0;
  &:hover {
    transform: scale(1.1);
  }
  svg {
    opacity: 0.5;
    width: 20px !important;
    height: 20px !important;
    transition: 0.2s ease;
    &:hover {
      opacity: 0.8;
    }
  }
}

@media (max-width: 500px) {
  .dockHeader {
    height: 36px;
    // padding: 20px;
    // display: none;
  }
  .dH__upperRow {
    border-bottom: none;
    height: 36px;
    background: $secondary3;
    padding-left: 10px;
  }
  .dH__bottomRow {
    display: none;
  }
  .dh__name {
    input {
      font-size: 14px;
    }
  }
  .dh__settings-icon {
    opacity: 1;
  }
  .dh__settings {
    position: absolute;
    right: 15px;
    top: 10px;
  }
}
