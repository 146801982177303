@import "../../theme.styles.scss";
.home {
  width: 100%;
  height: 100vh;
  background: $background1;
  // background-color: $secondary2;
  overflow: hidden;
}

.home__createSpace {
  background: white;
  height: 40px;
}
.home__content {
  // padding: 30px 0px 0px 80px;
  border-radius: 20px 0px 0px 0px;
  background: $background2;
  // height: 90%;
  justify-content: space-between;
  // display: grid;
  display: flex;
  // grid-template-columns: repeat(2, 1fr);
  // column-gap: 50px;
  height: calc(100vh - 80px);
  width: 100%;
  position: relative;
  z-index: 1;
  // overflow-y: auto;
  border-left: 1px solid $header2;
  // border-top: 1px solid $secondary3;
  // box-shadow: inset 1px 1px 1px rgba(54, 54, 54, 0.1),
  //   inset 1px 2px 2px rgba(54, 54, 54, 0.1),
  //   inset 1px 3px 3px rgba(54, 54, 54, 0.2);
  .sectionBox {
    margin-bottom: 30px;
  }
}

.home__content-container {
  display: flex;
  position: relative;
  width: 100%;
  background: $body;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
}

.home__firstcolumn {
  padding: 10px 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  // height: calc(100vh - 90px);
  justify-content: space-between;
  row-gap: 30px;
  // overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  //  ::-webkit-scrollbar-track {
  //   background: white;
  //   display: none;
  // }
  // ::-webkit-scrollbar {
  //   width: 5px;
  //   height: 8px;
  //   display: none;
  // }
  // ::-webkit-scrollbar-thumb {
  //   background: #888;
  //   border-radius: 4px;
  //   display: none;
  // }
  // /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  //   background: #3e3e3e;
  //   display: none;
  // }
}
.journalactive {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  color: $primary;
  .journal {
    box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
  }
  .widgetheader {
    background: $paper;
    padding: 5px 10px;
    border-radius: 5px 5px 0px 0px;
    align-items: center;
    box-shadow: rgb(0 0 0 / 20%) 0px -9px 30px -10px;
    // border: 1px solid black;
    // display: flex;
    justify-content: space-between;
    column-gap: 10px;
    width: fit-content;
    z-index: 5;
  }
  .widgetheader-chevron {
    transform: rotate(90deg);
    transition: all 0.5s ease;
  }
}
.journalinactive {
  display: flex;
  flex-direction: column;
  height: 35px;
  width: auto;
  overflow: hidden;
  color: $primary;
  .widgetheader {
    background: $paper;
    padding: 5px 10px;
    border-radius: 5px 5px 0px 0px;
    align-items: center;
    box-shadow: rgb(0 0 0 / 20%) 0px -9px 30px -10px;
    // border: 1px solid black;
    // display: flex;
    justify-content: space-between;
    column-gap: 10px;
    width: fit-content;
    z-index: 5;
  }
  .editor-inner {
    background: none;
  }
}
.journalinactive:after {
  content: "...";
  position: absolute;
  top: 35px;
  left: 45px;
}

.chartactive {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  // margin-bottom: 40px;
  .journal {
    box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
  }
  .widgetheader {
    background: white;
    padding: 5px 10px;
    border-radius: 5px 5px 0px 0px;
    align-items: center;
    box-shadow: rgb(0 0 0 / 20%) 0px -9px 30px -10px;
    // border: 1px solid black;
    // display: flex;
    justify-content: space-between;
    column-gap: 10px;
    width: fit-content;
    z-index: 5;
  }
  .widgetheader-chevron {
    transform: rotate(90deg);
  }
}
.chartinactive {
  display: flex;
  flex-direction: column;
  height: 60px;
  width: 100%;
  margin-bottom: 0px;
  // overflow: hidden;
  // box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
  // margin-bottom: 30px;
  .widgetheader {
    background: white;
    padding: 5px 10px;
    border-radius: 5px 5px 0px 0px;
    align-items: center;
    box-shadow: rgb(0 0 0 / 20%) 0px -9px 30px -10px;
    // border: 1px solid black;
    // display: flex;
    justify-content: space-between;
    column-gap: 10px;
    width: fit-content;
    z-index: 5;
  }
}

.widgetheader-row {
  display: flex;
  column-gap: 20px;
}

.habit__header-tab-active {
  // background: white;
  padding: 5px 10px;
  border-radius: 5px 5px 0px 0px;
  align-items: center;
  box-shadow: rgb(0 0 0 / 20%) 0px -9px 30px -10px;
  // border: 1px solid black;
  // display: flex;
  justify-content: space-between;
  column-gap: 10px;
  width: fit-content;
  z-index: 5;
}
.habit__header-tab-nonactive {
  // background: white;
  padding: 5px 10px;
  border-radius: 5px 5px 0px 0px;
  align-items: center;
  box-shadow: rgb(0 0 0 / 20%) 0px -9px 30px -10px;
  // border: 1px solid black;
  // display: flex;
  justify-content: space-between;
  column-gap: 10px;
  width: fit-content;
  z-index: 5;
}

.home__notiftest {
  button {
    background: $body;
    color: $primary;
    margin-top: 15px;
    margin-left: 25px;
    border-radius: 6px;
    padding: 5px;
    border: 1px solid $secondary3;
  }
}

.habitsactive {
  display: flex;
  flex-direction: column;
  // height: 100%;
  width: 100%;
  color: $primary;
  // margin-bottom: 0px;
  .journal {
    box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
  }
  .widgetheader {
    background: $paper;
    padding: 5px 10px;
    border-radius: 5px 5px 0px 0px;
    align-items: center;
    box-shadow: rgb(0 0 0 / 20%) 0px -9px 30px -10px;
    // border: 1px solid black;
    // display: flex;
    justify-content: space-between;
    column-gap: 10px;
    width: fit-content;
    z-index: 5;
  }
  .widgetheader-chevron {
    transform: rotate(90deg);
  }
}
.habitsinactive {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: $primary;
  // overflow: hidden;
  // box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
  .widgetheader {
    background: $paper;
    padding: 5px 10px;
    border-radius: 5px 5px 0px 0px;
    align-items: center;
    box-shadow: rgb(0 0 0 / 20%) 0px -9px 30px -10px;
    // border: 1px solid black;
    // display: flex;
    justify-content: space-between;
    column-gap: 10px;
    width: fit-content;
    z-index: 5;
  }
}

// .widgetheader {
//   background: white;
//   padding: 5px 10px;
//   border-radius: 5px 5px 0px 0px;
//   box-shadow: rgb(0 0 0 / 20%) 0px -9px 30px -10px;
//   // border: 1px solid black;
//   // display: flex;
//   justify-content: space-between;
//   column-gap: 10px;
//   width: fit-content;
//   z-index: 5;
// }

.home__zerocolumn {
  background: $background2;
  // box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
  min-width: 180px;
  // padding: 10px 0px 0px 0px;
  border-radius: 20px 0px 0px 0px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none; /* Firefox */
  //  ::-webkit-scrollbar-track {
  //   background: white;
  //   display: none;
  // }
  // ::-webkit-scrollbar {
  //   width: 5px;
  //   height: 8px;
  //   display: none;
  // }
  // ::-webkit-scrollbar-thumb {
  //   background: #888;
  //   border-radius: 4px;
  //   display: none;
  // }
  // /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  //   background: #3e3e3e;
  //   display: none;
  // }
  h3 {
    padding-left: 15px;
    font-size: 16px;
    color: $header2;
    font-weight: 500;
  }
}
.home__recentStations {
  button {
    background: #eff2f5;
    color: #333;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.workspaces__title {
  color: $primary;
  // opacity: 0.3;
  // padding-left: 10px;
  padding-top: 9px;
  // margin-right: 15px;
  // margin-left: 20px;
  text-align: end;
  padding-right: 15px;
  font-size: 1em;
  font-weight: 500;
  width: calc(100% - 15px);
  border-top: 0.5px solid $header2;
  // margin-bottom: 15px;
}

@media (max-width: 500px) {
  .home {
    height: 100%;
  }
  .welcomeHome {
    padding: 20px;
  }
  .home__content {
    border-top: none;
    border-radius: 0px 0px 0px 0px;
    padding: none;
    padding-bottom: none;
    display: flex;
    flex-direction: column-reverse;
    box-shadow: none;
    justify-content: flex-end;
    height: unset;
  }
  .home__content-container {
    display: flex;
    position: relative;
    width: 100%;
    // height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
  .home__zerocolumn {
    display: none;
  }
  .home__firstcolumn {
    padding: 10px;
    padding-bottom: 150px;
    height: 100%;
  }
  .journalinactive:after {
    display: none;
  }
  .journalactive {
    // min-height: 50vh;
    .editor-container {
      min-height: 60vh;
      .editor-inner {
        height: 100%;
        .editor-input {
          height: 100%;
        }
      }
    }
  }
  .widgetheader {
    h3 {
      font-weight: 500;
      font-size: 18px;
    }
  }
}
