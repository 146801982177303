@import "../../theme.styles.scss";
.focusapp {
  --mainn-color: #d95450;
  --btn-primary-activee: #443a3a4b;
  --btn-secondary-activee: #e7e7e7;
  font-size: 16px;
  position: relative;
}

/* body {
  background-color: var(--mainn-color);
  font-family: "Comfortaa", -apple-system, "system-ui", "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  color: white;
  text-align: center;
  width: 100vw;
  height: auto;
} */

.focus_container {
  background-color: var(--mainn-color);
  text-align: center;
  margin-bottom: 20px;
  margin-top: 15px;
  display: flex;
  border-radius: 0px;
  margin-right: 0;
  margin-left: 25px;
  padding: 0px;
  position: relative;
  align-items: flex-end;
}

.focus_wrapper {
  box-shadow: 4px 4px 12px 2px #00000021;
  background-color: rgb(248, 248, 248, 0.9);
  border: solid 1px #1d1c1c11;
  border-radius: 6px 6px 6px 6px;
  padding: 0.7rem 0.7rem;
  max-width: 185px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.clock__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: none;
}

.focus_clock {
  background-color: rgb(255, 255, 255, 0.1);
  border-radius: 50%;
  border: none;
}

.clock__title {
  color: #1d1d1d;
  font-size: 0.6rem;
  margin-bottom: 0.2rem;
}

.clock__value {
  color: #1d1d1d;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.focus_setup {
  margin-top: 0rem;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
}

.setup__type {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  /* grid-template-columns: 0.2fr 0.2fr; */
  column-gap: 10px;
  margin-bottom: 15px;
}

.btn {
  background-color: transparent;
}

.btn__type {
  padding: 0.4rem 0.8rem;
  border-radius: 6px;
  font-family: "Comfortaa";
  font-size: 1rem;
}

.btn__type:hover {
  background-color: $secondary2;
}

.btn__type--active {
  background-color: $secondary3;
  box-shadow: 1px 1px 4px 0px #00000021;
}

.length__title {
  font-size: 0.7rem;
}

.timerClockValue {
  color: #3e3e3e;
}

.length__config {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.length__break {
  margin-bottom: 0px;
}

.length__time {
  color: black;
  font-size: 0.8rem;
}

.btn__action {
  border-radius: 100%;
  padding: 2px 5px;
  font-size: 1.4rem;
  width: 2rem;
}

.btn__action:hover {
  background-color: $secondary2;
}

.focus_control {
  position: absolute;
  top: 7rem;
  display: flex;
  column-gap: 0.6rem;
  justify-content: space-evenly;
  align-items: center;
  margin: 0.2rem;
}

.btn__control {
  border-radius: 50%;
  border: 1px solid $secondary2;
  padding: 0.4rem;
  background-color: none;
}

.btn__reset {
  width: 30px;
  height: 30px;
}

.btn__icon {
  border-radius: 0px;
}

.btn__control span {
  /* font-size: 2rem; */
  color: #444444;
  width: 100%;
  height: auto;
}

.btn__control:hover {
  background-color: $secondary2;
}

.btn__control--active {
  background-color: $secondary2;
}

.timer__taskdetails {
  width: 120px;
  max-height: 30px;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid $secondary2;
  h5 {
    font-size: 12px;
    font-weight: 600;
  }
}

.focus__taskselectmenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  background: white;
  border-radius: 6px;
  box-shadow: $boxShadow-small;
  padding: 10px 5px;
  transform: translateX(100%);
}

@media (max-width: 500px) {
  .focus_container {
    // display: none;
    left: -70px;
    margin-bottom: 60px;
  }
  .focus_wrapper {
    background-color: rgb(248, 248, 248, 1);
    max-width: 100dvw;
    padding: 1rem 1rem;
    // display: flex;
    // width: 100dvw;
    // height: 100dvh;
  }
  .clock__wrapper {
    margin-top: -20px;
  }
  .clock__value {
    font-size: 4rem;
    margin-bottom: 1.5rem;
  }
  .clock__title {
    font-size: 1rem;
    font-weight: 400;
    max-width: 10rem;
  }
  .focus_control {
    top: 11rem;
    column-gap: 1rem;
  }
  .setup__type {
    column-gap: 20px;
  }
  .length__title {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 1.2rem;
  }
  .btn__action {
    font-size: 2rem;
    padding: 5px 10px;
  }
  .length__time {
    font-size: 2rem;
    font-weight: 400;
  }
  .btn__type {
    font-size: 1.2rem;
    font-weight: 400;
  }
  .btn__control span {
    font-size: 1rem;
    font-weight: 400;
  }
  .btn__reset {
    width: 54px;
    height: 54px;
    font-weight: 400;
  }
  .btn__startstop {
    width: 54px;
    height: 54px;
  }
  .length__config {
    justify-content: space-between;
  }
  .focus_setup {
    margin-top: 1rem;
    padding: 1rem 0;
  }
}
