@import "../../../theme.styles.scss";

.subtasks {
  display: flex;
  width: 100%;
  padding-bottom: 5px;
  padding-right: 6px;
  position: relative;
  cursor: pointer;
  margin-top: 2px;
}
.subtasks__label {
  display: flex;
  width: 100%;
  margin-left: 26px;
  margin-top: 2px;
  margin-bottom: 2px;
  // svg {
  //   // position: absolute;
  //   // transform: rotate(180deg);
  //   // height: 20px;
  //   // width: 20px;
  //   left: 6px;
  //   top: 0px;
  // }
}
.subtasks__task {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
}
// subtasks__label-connect {
//   display: flex;
//   color: $primary;
//   font-size: 9px;
//   padding: 1px 5px;
//   border: 1px solid $secondary3;
//   background: $body;
//   border-radius: 4px;
//   a {
//     color: $primary;
//   }
// }
.subtasks__label-connect {
  position: absolute;
  left: 11px;
  top: -6px;
  color: $header1;
  border-left: 1px solid $header1;
  border-bottom: 1px solid $header1;
  border-radius: 0px 0px 0px 8px;
  width: 12px;
  height: 16px;
}
.subtask__addFalse {
  margin-top: 2px;
  margin-left: 25px;
}
