@import "../../../theme.styles.scss";
.stationMenu {
  width: 100%;
  padding-left: 0;
  padding-bottom: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 80;
  &:hover {
    .fs__false {
    }
    .sm__settings {
      svg {
        opacity: 0.1;
        &:hover {
          opacity: 1;
        }
      }
    }
    .sm__favorite {
      svg {
        opacity: 0.6;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
.sm__upperRow {
  display: flex;
  box-sizing: border-box;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  background: $background1;
  border-bottom: 1px solid $header2;
  box-shadow: 1px 1px 1px rgba(54, 54, 54, 0.1),
    2px 2px 2px rgba(54, 54, 54, 0.2), 3px 3px 3px rgba(54, 54, 54, 0.3);
  // z-index: 1;
  padding-right: 480px;
}
.sm__buttomRow {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: 40px;
  // align-items: flex-end;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 20px;
  // padding-left: 120px;
  background: $background2;
  // border-bottom: 1px solid $secondary2;
  // margin-left: 20px;
  // bottom: 0px;
  // width: 100%;
  // z-index: 5;
}
.filterandsearch {
  display: flex;
  // width: 100%;
  // margin-left: 160px;`
  // margin-right: 80px;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  margin-top: 8px;
  // padding-right: 320px;
}
.sm__filters {
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: flex-start;
  // margin-right: 20px;
  // margin-right: 100px;
  // align-self: flex-start;
  // justify-self: flex-start;
  // margin-top: 45px;
  // margin-left: 150px;
  // padding: 5px 15px;
  // position: absolute;
  right: 250px;
  column-gap: 20px;
  z-index: 100;
  // transform: translateX(-150px);
  // justify-content: space-between;
}
.sm__filters-group {
  display: flex;
  position: absolute;
  // margin-right: -60px;
}
.sm__filters-togglegroup {
  // transform: translateY(21px);
  display: flex;
  column-gap: 10px;
  justify-content: flex-end;
  border-radius: 6px;
  // width: 60px;
  // height: 32px;
  cursor: pointer;
  z-index: 101;
}
.sm__filters-toggle {
  // transform: translateY(40px) translateX(-102px);
  border: 1px solid $header2;
  border-bottom: none;
  background: $secondary2;
  border-radius: 6px 6px 0px 0px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    color: $primary;
  }
}
.sm__filters-toggle-active {
  // transform: translateY(40px) translateX(-102px);
  border: 1px solid $primary;
  border-bottom: none;
  background: $secondary2;
  border-radius: 6px 6px 0px 0px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    color: $primary;
  }
}
.sm__filters-search {
  // transform: translateY(40px) translateX(-102px);
  border: 1px solid $header2;
  border-bottom: none;
  // border-top: none;
  background: $secondary2;
  border-radius: 6px 6px 0px 0px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  transition: width 0.3s ease-in;
  // position: absolute;
  // transform: translateX(-290px) translateY(19px);
  cursor: pointer;
  svg {
    color: $primary;
  }
  input {
    opacity: 0;
    width: 0px;
    transition: width 0.3s ease-in;
  }
}
.sm__filter-search-active {
  // transform: translateY(40px) translateX(-102px);
  border: 1px solid $header2;
  border-bottom: none;
  // border-top: none;
  background: $secondary2;
  border-radius: 6px 6px 0px 0px;
  width: 200px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  transition: width 0.3s ease-in;
  // transform: translateX(-290px) translateY(19px);
  svg {
    width: 1em;
    height: 1em;
  }
  input {
    opacity: 1;
    width: 140px;
    border-radius: 6px;
    border: none;
    background: $secondary2;
    padding: 2px 5px;
    // max-width: 100px;
    transition: width 0.3s ease-in;
    &:focus {
      border: none;
      outline: none;
    }
  }
}
.sm__search-results {
  position: absolute;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  background: $body;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid $secondary2;
  box-shadow: $boxShadow-large;
  row-gap: 5px;
  padding: 5px 10px;
  width: 200px;
  margin-left: -6px;
  top: 40px;
  z-index: 2;
  h4 {
    color: $primary;
    margin: 5px 0px;
    text-decoration: underline 2px $secondary2;
  }
}
.sm__search-result {
  // box-shadow: 0 0.1rem 0.8rem -0.5rem rgba(0, 0, 0, 0.4);
  background: white;
  border-radius: 6px;
  border: 1px solid $secondary3;
  padding: 6px;
  font-size: 0.8em;
  cursor: pointer;
}
.md__container {
  display: flex;
  // padding: 5px 15px;
  column-gap: 20px;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 1100px) {
  .stationMenu {
    width: 100vw;
    overflow-x: auto;
    overflow-y: hidden;
    padding-right: 0;
    padding-left: 0;
  }
  .sm__buttomRow {
    flex-direction: column-reverse;
    height: fit-content;
  }
}

@media (max-width: 500px) {
  .stationMenu {
    overflow-x: hidden;
    overflow-y: hidden;
    padding-right: 0;
    padding-left: 0;
  }
  .sm__upperRow {
    // display: none;
    border-bottom: none;
    padding-left: 0px;
    width: 100vw;
    height: 36px;
    background: $secondary3;
  }
  .sm__search-results {
    position: fixed;
    margin-left: 15px;
    width: 70vw;
    top: 30px;
    margin-top: 55px;
  }
  .filterandsearch {
    margin-left: 0px;
    column-gap: 8px;
    margin-top: 0px;
  }
  .sm__buttomRow {
    width: 100vw;
    flex-direction: column-reverse;
    height: fit-content;
    padding: 0px 0px;
  }
  .md__container {
    display: none;
    // padding: 5px 15px;
    flex-direction: row;
    justify-content: space-around;
    width: 100vw;
  }

  .sm__filters {
    display: flex;
    // height: 80px;
    // margin-top: 40px;
    // position: absolute;
    // right: 0px;
    column-gap: 20px;
    z-index: 100;
  }

  .sm__filters-togglegroup {
    transform: unset;
    // margin-right: 50px;
    border: none;
    width: 32px;
  }
  .sm__filters-toggle {
    border: none;
    margin-top: 6px;
  }
  .sm__filters-toggle-active {
    border: none;
    background: none;
    margin-top: 6px;
  }

  .sm__filters-search {
    // transform: translateY(40px) translateX(-102px);
    // border-top: none;
    background: $secondary2;
    border-radius: 6px;
    border: none;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    transition: width 0.3s ease-in;
    // position: absolute;
    // right: 90px;
    transform: unset;
    transform: translateY(3px);
    // top: -50px;
    z-index: 94;
    // transform: translateY(30px) , translateX(-30px);
    cursor: pointer;
    svg {
      color: $primary;
    }
    input {
      opacity: 0;
      width: 0px;
      transition: width 0.3s ease-in;
    }
  }

  .sm__filter-search-active {
    position: absolute;
    width: calc(100vw - 60px);
    right: 55px;
    height: 34px;
    top: 4px;
    border: 1px solid $secondary3;
    z-index: 100;
    text-align: start;
    input {
      width: 100%;
      margin-left: 10px;
    }
  }

  .sm__filters-group {
    left: 0px;
    top: 0px;
    z-index: 100;
  }

  .home__welcomeHome {
    position: absolute;
    top: 5px;
    left: 60px;
    // z-index: 90;
    background: none;
    background-color: none;
    .welcomeHome {
      background: none;
      box-shadow: none;
    }
    .wH__bottomRow {
      background: none;
      h4 {
        display: none;
      }
    }
  }
}
