@import "../../../../theme.styles.scss";
.statusTypeList {
  margin: 8px;
  border-radius: 2px;
  height: fit-content;
  // width: 70vw;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  flex: 1;
  .retroBox {
    padding: 0px;
    box-shadow: none;
  }
}

.stl__content {
  border-radius: 10px;
}
.stl__taskList {
  padding: 4px 8px;
  min-height: 20px;
  flex-grow: 1;
  transition: 0.2s background-color;
}
.stl__newTask {
  margin-top: 10px;
  input {
    width: 100%;
    background: transparent;
    border: none;
    &:focus {
      width: 300px;
      background: white;
      border: none;
      transform: scale(1);
    }
  }
  ::placeholder {
    color: rgb(151, 151, 151);
  }
}

.stl__header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;
}

.stl__menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  padding-top: 20px;
  position: relative;
  width: 100%;
  .bll__content {
    left: -50%;
    top: 60px;
    .retroBox {
    }
  }
}

.stl__settings,
.stl__expand,
.stl__drag {
  opacity: 0.1;
  transition: 0.2s ease;
  cursor: pointer;
  margin-left: 10px;
  &:hover {
    opacity: 0.4;
  }
}

.stl__title {
  max-width: 100%;
  input {
    background: transparent;
    border: 1px dotted rgba(0, 0, 0, 0);
    padding: 3px 5px;
    border-radius: 5px;
    // margin-left: -10px;
    outline: none;
    max-width: 100%;
    font-size: 18px;
    font-weight: 800;
    color: $header1;
    &:hover {
      border: 1px dotted $header2;
    }
    &:focus {
      border: 1px dotted $header2;
      &::placeholder {
        color: gray;
      }
    }
  }
}

.stl__arrow {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary;
  svg {
    transition: 0.2s ease;
    margin-top: 5px;
    &:hover {
      transform: scale(1.2);
    }
  }
}

@media (max-width: 500px) {
  .stl__title {
    input {
      max-width: 180%;
    }
  }
}
