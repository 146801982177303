@import "../../theme.styles.scss";
.allHome {
  display: flex;
  flex-direction: row;
  background: $background2;
  // box-shadow: $boxShadow-small;
  // border-bottom: 1px solid $primary;
}
.homeIconAll {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 180px;
  height: 80px;
  // padding-top: 30px;
  background: $background1;
  border-right: 1px solid $header2;
  // padding: 25px 25px;
  border-radius: 0px 0px 15px 0px;
}
// .homeIconLeft {
//   width: 40px;
//   background: $secondary2;
//   // border-radius: 0px 0px 6px 0px;
//   // box-shadow: 1px 1px 1px rgba(54, 54, 54, 0.1),
//   // 1px 2px 2px rgba(54, 54, 54, 0.2),
//   // 1px 3px 3px rgba(54, 54, 54, 0.3);
// }
.homeIcon {
  position: relative;
  background: white;
  margin-top: 7px;
  padding: 6px 60px;
  // border-right: 1px solid black;
  // border-bottom: 5px solid white;
  border-radius: 6px 12px 0px 0px;
  box-shadow: inset 1px 1px 1px rgba(54, 54, 54, 0.1),
    inset 1px 2px 2px rgba(54, 54, 54, 0.2),
    inset 1px 3px 3px rgba(54, 54, 54, 0.3);
}
.wH__upperRow {
  display: flex;
  box-sizing: border-box;
  height: 40px;
  align-items: center;
  // background: $secondary2;
  border-bottom: 1px solid $header2;
  // justify-content: space-between;
  padding-right: 20px;
  // box-shadow: 1px 1px 1px rgba(54, 54, 54, 0.1),
  //   2px 2px 2px rgba(54, 54, 54, 0.2), 3px 3px 3px rgba(54, 54, 54, 0.3);
  // z-index: 1;
}
.wH__everything {
  display: flex;
  box-sizing: border-box;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  // background: $secondary2;
  // border-bottom: 1px solid black;
  margin-right: 160px;
  // box-shadow: 1px 1px 1px rgba(54, 54, 54, 0.1),
  //   2px 2px 2px rgba(54, 54, 54, 0.2), 3px 3px 3px rgba(54, 54, 54, 0.3);
  // z-index: 1;
  h2 {
    color: $primary;
    font-size: 1.2em;
    font-weight: 600;
  }
  svg {
    color: $primary;
  }
}
.wH__bottomRow {
  display: flex;
  height: 40px;
  background: $background2;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  padding-left: 32px;
  color: $primary;
  p {
    font-weight: 500;
    strong {
      font-weight: 550;
    }
  }
  h4 {
    font-weight: 500;
  }
}

.welcomeHome {
  // border-bottom: 1px solid black;
  // border-radius: 0px 0px 0px 6px;
  width: 100%;
  position: relative;
  background: $background1;
  // padding: 27px 50px;
  box-sizing: border-box;
  // box-shadow: 1px 1px 1px rgba(54, 54, 54, 0.1),
  // 1px 2px 2px rgba(54, 54, 54, 0.2),
  // 1px 3px 3px rgba(54, 54, 54, 0.3);
  h3 {
    border: none;
    font-weight: 300;
    // padding-right: 140px;
    span {
      font-size: 25px;
    }
  }
}
@media (max-width: 500px) {
  .welcomeHome {
    padding: 5px 8px;
    box-shadow: 0px 2px 4px rgba(54, 54, 54, 0.1);
    background: $secondary3;
    h3 {
      border: none;
      font-weight: 300;
      font-size: 14px;
      span {
        display: none;
      }
    }
    p {
      font-size: 12px;
    }
  }
  .wH__upperRow {
    height: 28px;
    border-bottom: none;
    display: none;
  }
  .wH__everything {
    display: none;
    border-bottom: none;
  }
  .wH__bottomRow {
    padding-left: 1px;
    height: 28px;
    background: none;
  }
  .homeIconAll {
    display: none;
    position: absolute;
    top: 8px;
    left: -22px;
    height: 40px;
    padding: 0px 0px;
    z-index: 100;
    border-right: none;
    background: none;
    img {
      height: 32px;
    }
  }
  .wH__bottomRow-dragtip {
    display: none;
  }
  .allHome {
    height: 36px;
  }
}
