@import "../../theme.styles.scss";

.addtask__btn {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  right: 30px;
  bottom: 106px;
//   right: 114px;
//   bottom: 30px;
  padding: 6px 12px;
  color: $primary;
  border-radius: 50%;
  border: 1px solid $border1;
  cursor: pointer;
  z-index: 50;
  background-color: $paper;
  box-shadow: 2px 2px 8px 1px #00000021;
  svg {
    opacity: 1;
    &:hover {
      opacity: 0.7;
    }
  }
}

@media (max-width: 500px) {
  .addtask__btn {
    position: fixed;
    // height: 52px;
    // width: 52px;
    padding: none;
    // left: calc(100vw - 150px);
    right: 15px;
    padding: 5px 15px;
    bottom: 128px;
    align-items: center;
    justify-content: center;
    // top: 300px;
    // left: 150px
  }
}
