// @import '~@fullcalendar/core/main.css';
// @import '~@fullcalendar/daygrid/main.css';
@import "../../theme.styles.scss";

.minical {
  // border-left: 4px solid $secondary2;
  width: 500px;
  height: calc(100vh - 143px);
  // margin-bottom: 30px;
  // padding: 0px 0px 10px 0px;
  background: $paper;
  color: $primary;
  box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
  padding: 0px 0px 10px 0px;
  border-radius: 0px 5px 5px 5px;
  .calendar__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    // font-family: sans-serif;
    // text-align: center;
  }

  ///HEADER ONELINE

  .fc .fc-toolbar.fc-header-toolbar {
    display: flex;
    flex-direction: row;
  }

  .fc-toolbar.fc-header-toolbar {
    display: flex;
    flex-direction: row;
  }
  .fc-toolbar.fc-header-toolbar .fc-left {
    order: 3;
  }
  .fc-toolbar.fc-header-toolbar .fc-center {
    order: 1;
  }
  .fc-toolbar.fc-header-toolbar .fc-right {
    order: 2;
  }

  ///

  .fc-scrollgrid {
    border-color: transparent !important;
  }
  .fc-scrollgrid td:last-of-type {
    border-right-color: transparent !important;
  }
  .fc-scrollgrid-section.fc-scrollgrid-section-body td[role="presentation"] {
    border-bottom-color: transparent !important;
  }
  [role="row"]:last-of-type td {
    border-bottom-color: transparent !important;
  }
  th[role="presentation"] {
    border-right-color: transparent !important;
  }
  .fc-theme-standard th[role="columnheader"] {
    border-right-color: transparent !important;
  }
  .fc-theme-standard td[role="columnheader"] {
    border-right-color: transparent !important;
  }
  th[class="fc-timegrid-axis"] {
    border-right-color: transparent !important;
  }

  // .list__column-wrapper {
  //   overflow-y: scroll;
  // }

  .modaldiv {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: flex-end;
  }

  .fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
    font-size: 0.8em;
    font-weight: 500;
  }

  .list__column {
    box-sizing: border-box;
    background-color: $body;
    position: relative;
    z-index: 1;
    height: calc(100vh - 90px);
    width: 200px;
    padding: 20px 12px;
    box-shadow: 1px 1px 1px rgba(54, 54, 54, 0.1);
    // 2px 2px 2px rgba(54, 54, 54, 0.2), inset 1px 1px 1px rgba(54, 54, 54, 0.1),
    // inset 2px 2px 2px rgba(54, 54, 54, 0.2);
    ul {
      list-style-type: none;
    }
  }

  .list__column-description {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    margin-bottom: 24px;
    h4 {
      text-align: center;
    }
    p {
      font-size: 11px;
      color: $secondary;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }

  .fc .fc-button-primary {
    background: white;
    color: #000000;
    border-color: #dce1ec;
    font-size: 14px;
    border-radius: 0px 0px 4px 4px;
    border: none;
  }

  .fc .fc-button-primary:not(:disabled).fc-button-active,
  .fc .fc-button-primary:not(:disabled):hover {
    background: $secondary3;
    color: $primary;
    border-color: #dce1ec;
    font-size: 14px;
  }

  .fc .fc-button-primary:disabled {
    background: rgb(247, 248, 251);
    color: #c5c5c5;
    border-color: #dce1ec;
    font-size: 14px;
  }

  .fc .fc-timegrid-axis-cushion {
    font-size: 11px;
  }

  // .fc .fc-daygrid-day.fc-day-today {
  //   background-color: rgba(#ebebeb, 0.6);
  //   background-color: var(--fc-today-bg-color, rgba(#ebebeb, 0.6));
  // }

  // .fc .fc-timegrid-col.fc-day-today {
  //   background-color: rgba(#ebebeb, 0.2);
  //   background-color: var(--fc-today-bg-color, rgba(#ebebeb, 0.2));
  // }

  // .fc-col-header {
  //   background-color: rgba(#ebebeb, 0.8);
  //   background-color: var(--fc-today-bg-color, rgba(#ebebeb, 0.8));
  // }

  .fc .fc-toolbar.fc-header-toolbar {
    padding: 0em 0em 0em 0em;
    margin-bottom: 0em;
  }

  .fc .fc-toolbar-title {
    font-size: 1em;
    margin: 0;
    // margin-left: 5px;
    // margin-right: 5px;
    font-weight: 600;
  }

  .fc-toolbar-chunk {
    button {
      font-weight: 500;
    }
  }

  // .this_project-items {
  //   background-color: #303030;
  //   border: #303030;
  //   color: white;
  // }
  // .other_project-items {
  //   background-color: #a12f2f;
  //   border: #a12f2f;
  // }

  .ProjectsButton {
    .fc-button {
      color: #a12f2f;
    }
  }

  .fc-icon-print::before {
    font-family: "Material Icons";
    content: "\e8ad";
    font-size: 24px;
  }

  .fc-WorkHoursButton-button {
    padding: 0 3px !important;
  }
  .fc-ProjectsButton-button {
    padding: 0 3px !important;
    &:active {
      background-color: #a12f2f;
    }
  }

  .calendar_filters-buttons {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0px;
    row-gap: 30px;
    padding: 50px 20px;
    width: 200px;
    transition: all 0.3s ease;
  }
  .calendar_filters-buttons-off {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0px;
    row-gap: 20px;
    padding: 50px 20px;
    margin-right: -200px;
    width: 200px;
    transition: all 0.3s ease;
  }

  .cal__allprojects {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    .cal__seekhide-tip {
      height: 0px;
      display: none;
      opacity: 0;
      transition: all 0.6s ease;
    }
    &:hover {
      .cal__seekhide-tip {
        height: 60px;
        display: flex;
        opacity: 1;
        transition: all 0.6s ease;
      }
    }
  }
  .cal__print-btn {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
    padding: 3px 5px;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      color: black;
      background: $secondary3;
    }
  }
  .cal__allprojects-btn {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
    padding: 3px 5px;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      color: black;
      background: $secondary3;
    }
  }
  .cal__unplanned-btn {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
    padding: 3px 5px;
    border-radius: 8px;
    &:hover {
      color: black;
      background: $secondary3;
    }
  }
  .cal__activehours {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    .cal__activehours-set {
      height: 0px;
      display: none;
      opacity: 0;
      transition: all 0.6s ease;
    }
    &:hover {
      .cal__activehours-set {
        height: 200px;
        display: flex;
        opacity: 1;
        transition: all 0.6s ease;
      }
    }
  }
  .cal__activehours-btn {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
    padding: 3px 5px;
    border-radius: 8px;
    &:hover {
      color: black;
      background: $secondary3;
    }
  }
  .cal__activehours-set {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 10px;

    button {
      border: 1px solid $secondary2;
      background: white;
      border-radius: 4px;
      padding: 3px 5px;
      color: $primary;
      width: 100%;
      &:hover {
        color: black;
        background: $secondary3;
      }
    }
  }

  .cal__slotpickers {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 5px;
    input {
      border-radius: 4px;
      border: 1px solid $secondary2;
      outline: none;
      padding: 1px 4px;
      // background: $secondary3;
      // border-bottom: 4px solid $secondary2;
    }
  }

  .cal__event-done {
    color: $secondary2;
    text-decoration: line-through;
    .fc-event {
      background-color: blue;
    }
    .fc-timegrid-event-harness {
      background-color: blue;
      text-decoration: line-through;
    }
    .fc-list-event-dot {
      background: #a12f2f;
      // border-color: #a12f2f;
      border-color: none;
    }
  }

  .fc .fc-daygrid-day.fc-day-today {
    background-color: rgba(#ebebeb, 0.6);
    background-color: var(--fc-today-bg-color, rgba(#ebebeb, 0.6));
  }

  .fc .fc-timegrid-col.fc-day-today {
    background-color: rgba(#ebebeb, 0.2);
    background-color: var(--fc-today-bg-color, rgba(#ebebeb, 0.2));
  }
}

.minical-wide {
  width: 500px;
  z-index: 5;
}

@media (max-width: 500px) {
  .minical {
    height: calc(100vh - 90px);
    // height: 180vh;
    margin-left: -20px;
    margin-bottom: 30px;
    overflow-x: hidden;
    // padding: 0px;
    width: 100vw;
    // box-shadow: rgb(0 0 0 / 20%) 10px 9px 10px -10px;
  }
  // .minical-wide {
  //   .fc-toolbar-chunk:last-child {
  //     display: none;
  //   }
  //   width: 50vw;
  // }
  .minical-wide {
    .fc-toolbar-chunk:nth-child(2) {
      // display: none;
      position: relative;
      margin-bottom: -58px;
      margin-left: -120px;
      margin-right: 30px;
    }
    width: 50vw;
  }
  .fc .fc-toolbar.fc-header-toolbar {
    display: flex;
    flex-direction: column-reverse;
    padding: 5px;
    padding-top: 10px;
    row-gap: 6px;
  }
  .fc .fc-button-primary {
    font-size: 14px;
    padding: 6px;
  }
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    font-size: 12px;
    padding: 4px;
  }
  .fc-toolbar-chunk {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    &:first-child {
      width:auto; 
    }
    button {
      padding: 0px;
    }
  }
  .fc-dayGridMonth-button {
    padding: 0px;
  }
  .fc-button {
    padding: 0px;
  }

  .fc-event-time,
  .fc-event-title {
    padding: 0 1px;
    white-space: nowrap;
  }

  .fc-title {
    white-space: normal;
  }
  element.style {
  }
  .fc .fc-timegrid-axis-cushion,
  .fc .fc-timegrid-slot-label-cushion {
    padding: 0 4px;
  }
  .fc .fc-timegrid-slot-label-cushion {
    display: inline-block;
    white-space: nowrap;
  }
  .fc,
  .fc *,
  .fc *:before,
  .fc *:after {
    box-sizing: border-box;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  // * {
  //   margin: 0px;
  //   box-sizing: border-box;
  //   font-family: "Raleway", sans-serif;
  //   font-weight: 300;
  // }
  user agent stylesheet div {
    display: block;
  }
  .fc-direction-ltr .fc-timegrid-slot-label-frame {
    text-align: right;
  }
}
