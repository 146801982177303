@import "../../theme.styles.scss";

.clients {
  display: flex;
  flex-direction: column;
  background: $paper;
  color: $primary;
  box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
  border-radius: 0px 5px 5px 5px;
  padding: 15px 10px 10px 10px;
  height: calc(60vh - 201px);
}

.widgetheader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background: $paper;
  color: $primary;
  padding: 5px 10px;
  border-radius: 5px 5px 0px 0px;
  align-items: center;
  box-shadow: rgb(0 0 0 / 20%) 0px -9px 30px -10px;
  // border: 1px solid black;
  // display: flex;
  justify-content: space-between;
  column-gap: 10px;
  width: fit-content;
  z-index: 5;
  // border: 1px solid black;
  // z-index: 5;
  h3 {
    font-size: 16px;
  }
}

.widgetheader-nonactive {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: $primary;
  // background: $paper;
  padding: 5px 10px;
  border-radius: 5px 5px 0px 0px;
  align-items: center;
  box-shadow: rgb(0 0 0 / 20%) 0px -9px 30px -10px;
  // border: 1px solid black;
  // display: flex;
  justify-content: space-between;
  column-gap: 10px;
  width: fit-content;
  z-index: 5;
  // border: 1px solid black;
  // z-index: 5;
  h3 {
    font-size: 16px;
    font-weight: 500;
    font-family: "Noto Sans JP", sans-serif;
    color: $header2
  }
}

.widgetname {
  padding: 5px 10px;
  border-radius: 5px 5px 0px 0px;
  box-shadow: rgb(0 0 0 / 20%) 0px -9px 30px -10px;
}

.widgetbtns {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  column-gap: 5px;
  border-radius: 5px 5px 0px 0px;

  button {
    // background: gray;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    min-width: 40px;
    font-weight: 500;
    min-height: 25px;
    padding: 5px 10px;
    background: $secondary3;
    border-radius: 5px;
    svg {
      width: 20px;
      height: 20px;
    }
    // font-weight: 600;
    // box-shadow: rgb(0 0 0 / 20%) 0px 1px 30px -5px;
  }
}

.clientadd-btn {
  margin-bottom: 5px;
}

.client__header-tabs {
  position: relative;
  display: flex;
  column-gap: 10px;
  // transform: translateY(-36px);
}

.client__header-tab {
  background: $paper;
  padding: 5px 10px;
  border-radius: 5px 5px 0px 0px;
  align-items: center;
  box-shadow: rgb(0 0 0 / 20%) 0px -9px 30px -10px;
  // border: 1px solid black;
  // display: flex;
  justify-content: space-between;
  column-gap: 10px;
  width: fit-content;
  z-index: 5;
  h3 {
  font-weight: 500;
  font-family: "Noto Sans JP", sans-serif;
}
}

.client__header-tab-nonactive {
  // background: white;
  padding: 5px 10px;
  border-radius: 5px 5px 0px 0px;
  align-items: center;
  box-shadow: rgb(0 0 0 / 20%) 0px -9px 30px -10px;
  // border: 1px solid black;
  // display: flex;
  justify-content: space-between;
  column-gap: 10px;
  width: fit-content;
  z-index: 5;
  h3 {
    font-weight: 400;
    font-family: "Noto Sans JP", sans-serif;
  }
}

.client-lead-btngroup {
  display: flex;
  align-items: center;
  column-gap: 18px;
}

.client-lead-convert-btn {
  display: flex;
  justify-content: center;
  // align-items: center;
  // width: 50px;
  height: 20px;
  align-items: center;
  cursor: pointer;
  svg {
    color: $primary;
    width: 17px;
    height: 17px;
  }
  :hover {
    .tooltip {
      opacity: 1;
      z-index: 200;
    }
  }
}

.client__today-btn {
  width: auto;
  padding: 5px;
  padding-top: 2px;
  // text-decoration: underline 1.5px solid;
  display: flex;
  align-items: center;
  justify-content: center;
}
.client__today-btn-passive {
  width: auto;
  color: $secondary2;
  padding-top: 2px;
  // text-decoration: line-through;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clientcontent {
  text-align: center;
  padding: 0px 0px 10px 0px;
  display: flex;
  flex-direction: column;
  height: calc(60vh - 201px);
}

.clientline-main {
  text-align: center;
  padding: 10px;

  // box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: space-between;
  h5 {
    font-size: 14px;
    font-weight: 500;
    text-align: start;
  }
}
.clientline {
  text-align: center;
  padding: 10px;

  // box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: flex-start;
  span {
    font-size: large;
    background: $secondary3;
    border-radius: 100%;
    padding: 5px;
    align-items: center;
    justify-content: center;
  }
  h5 {
    font-size: 14px;
    font-weight: 500;
    text-align: start;
  }
}

.clientline-confetti {
  position: fixed;
  width: 180px;
  display: flex;
  margin-left: 150px;
}

.client-progress-icon {
  display: flex;
  position: relative;
  // margin-left: -47px;
  width: 28px;
  height: 28px;
  // background: $secondary3;
  // padding: 5px;
  align-items: center;
  justify-content: center;
  // border-radius: 50%;
  svg {
    width: 22px;
    height: 22px;
  }
}

.client-progress-circle {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100px;
  // transform: translate(-50px);
  // margin-left: -47px;
}

.client__btn-group {
  display: flex;
  align-self: center;
  justify-content: flex-end;
  column-gap: 5px;
  right: 5px;
  button {
    width: 25px;
    height: 25px;
    background: $secondary3;
    border-radius: 5px;
    font-weight: 600;
  }
}

.client-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    width: 18px;
    height: 18px;
    color: $primary;
  }
}
.client-checkbox-colored {
  display: flex;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
    color: #1a82c4;
  }
}

.client__name-check {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
  justify-content: flex-end;
  column-gap: 5px;
  right: 5px;
  h3 {
    align-self: flex-start;
  }
  // button {
  //   width: 25px;
  //   height: 25px;
  //   background: $secondary3;
  //   border-radius: 5px;
  //   font-weight: 600;
  // }
}

@media (max-width: 500px) {
  .widgetbtns {
    column-gap: 8px;
    button {
      svg {
        width: 24px;
        height: 24px;
      }
      // font-weight: 600;
      // box-shadow: rgb(0 0 0 / 20%) 0px 1px 30px -5px;
    }
  }
  .widgetheader {
    h3 {
      font-size: 18px;
    }
  }
  .client__header-tabs {
    h3 {
      font-size: 18px;
      font-weight: 500;
    }
  }
  .clientline-main {
    h5 {
      font-size: 16px;
    }
  }
  .client-checkbox {
    svg {
      width: 22px;
      height: 22px;
    }
  }
  .client-lead-convert-btn {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
