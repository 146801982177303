@import "../../theme.styles.scss";
.app__notifications-container {
  display: flex;
  // position: fixed;
  justify-content: space-between;
  align-items: center;
  // width: 100vw;
  z-index: 90;
  box-shadow: 0px 2px 3px -2px rgba(0, 0, 0, 0.4);
}

.app__notifications {
  display: flex;
  position: fixed;
  right: 0;
  top: 0;
  margin-right: 21px;
  padding-top: 15px;
  flex-direction: row;
  justify-content: flex-end;
  // height: 45px;
  // min-width: 70px;
  align-items: flex-start;
  //   background: black;
  z-index: 90;
  overflow-y: visible;
  svg {
    color: $header1;
  }
}

.notification__sliding {
  position: absolute;
  right: 80px;
  // width: 100px;
  display: flex;
  p {
  font-weight: 500;
}
}

.notification__sliding-call {
  // position: absolute;
  margin-right: 100px;
  display: flex;
  width: 120px;
}

.notification__call {
  background: white;
  border-radius: 12px;
  padding: 20px 15px 15px 15px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  box-shadow: rgb(0 0 0 / 40%) 0px 9px 30px -10px;
  align-items: center;
  .avatar {
    display: flex;
    width: 100%;
    height: 100%;
  }
}

.notification__call-btns {
  display: flex;
  column-gap: 10px;
  align-items: center;
  button {
    display: flex;
    column-gap: 8px;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
  }
}

.notf_call-btn-decline {
  padding: 8px;
  font-size: 0.8em;
  color: white;
  background: rgb(174, 0, 0);
  svg {
    color: white;
  }
}

.notf_call-btn-accept {
  padding: 10px;
  color: white;
  background: rgb(0, 97, 0);
  svg {
    color: white;
  }
}

.notifications__badge {
  background: white;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  padding: 1px;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: center;
  display: flex;
  top: -7px;
  left: -9px;
  position: relative;
  z-index: -1;
  i {
    font-size: 9px;
  }
}

.person__notifications {
  display: flex;
  position: absolute;
  right: 35px;
  width: 300px;
  top: 35px;
  flex-direction: column;
  // height: 60px;
  row-gap: 10px;
  padding: 10px 15px;
  align-items: center;
  background: $body;
  z-index: 90;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 40%) 0px 9px 30px -10px;
  button {
    background: $secondary3;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 11px;
    &:hover {
      text-decoration: underline Black 2px;
    }
  }
}

.notifications__btngroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  button {
    font-size: 14px;
    font-weight: 500;
  }
}

@media (max-width: 500px) {
  .app__notifications-container {
    height: 50px;
    position: fixed;
    width: 100%;
    padding: 5px 20px;
    background: $paper;
  }
  .app__notifications {
    // position: absolute;
    // right: 92px;
    display: flex;

    margin-right: 10px;
  }
  .person__notifications {
    width: calc(100vw - 24px);
    right: 5px;
    top: 46px;
    button {
      font-size: 16px;
    }
  }
  .notifications__btngroup {
    button {
      font-size: 14px;
    }
  }
}
